import { CSSProperties, FC, ReactNode } from 'react';
import { TColorName } from 'styles';

import './flexComponent.scss';

type TProps = {
  backgroundColor?: TColorName | 'transparent';
  children: ReactNode;
  flexDirection?: 'row' | 'column';
  gap?: number;
  padding?: number;
  style?: CSSProperties;
  width?: 'full' | 'auto';
};

export const FlexComponent: FC<TProps> = ({ backgroundColor = 'coreWhite100', children, width, flexDirection, gap, padding }) => {
  const wrapperStyle: CSSProperties = {
    backgroundColor: backgroundColor,
    flexDirection: flexDirection || 'row',
    gap: gap ? `${gap}rem` : '0',
    justifyContent: 'stretch',
    padding: padding ? `${padding}rem` : '0',
    width: width === 'full' ? '100%' : 'auto',
  };

  return (
    <div className="page-content-wrapper" style={wrapperStyle}>
      {children}
    </div>
  );
};
