export function isArray(value) {
  return Array.isArray(value);
}

export function isEmptyArray<T>(value: T[]): boolean {
  if (!value) {
    return false;
  }
  return isArray(value) && value.length === 0;
}
