import { useTranslation } from 'react-i18next';
import { HttpClient } from '@aviobook/_http';
import { queryClient } from '@aviobook/index';
import { toastError, toastSuccess } from '@aviobook/Toastify';
import { useMutation } from '@tanstack/react-query';
import { QUERY_KEYS } from 'types';
import { TQuickReply } from 'types/src/quickReply';

function updateQuickReply(values: TQuickReply): Promise<TQuickReply[]> {
  return HttpClient.post('/api/v1/admin/quick-replies', { description: values.description, text: values.text });
}

export function useUpdateQuickReplies() {
  const { t } = useTranslation();
  return useMutation([QUERY_KEYS.QuickReplies], updateQuickReply, {
    onError: () => {
      toastError(t('TOAST_ERROR'));
    },
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.QuickReplies]);
      toastSuccess(t('TOAST_SUCCESS'));
    },
  });
}
