import { FC } from 'react';
import { Avatar, StatusDot, Text } from "@aviobook/_shared";
import { getIconForRole } from 'shared';
import { TCrewMember } from 'types';

import './flightroomInfoItem.scss';

type TProps = {
  crewMember: TCrewMember;
};

export const FlightroomInfoItem: FC<TProps> = ({ crewMember }) => {
  return (
    <div className="flightroom-info-item-container">
      <div className="flightroom-info-left">
        <section className="flightroom-info-icon">
          <Avatar icon={getIconForRole(crewMember.jobTitle)} isDisabled />
        </section>
        <section className="flightroom-info-text">
          <Text color="aviobookWebAccentRomeo07" size="SM" weight="bold">
            {crewMember.jobTitle}
          </Text>
          <Text color="aviobookLightLegacyPrimaryAlpha" size="SM">
            {crewMember.username}
          </Text>
        </section>
      </div>
      <section className="flightroom-info-right">
        <StatusDot size="small" userId={crewMember.id} />
      </section>
    </div>
  );
};
