import { FC } from 'react';
import { Text } from '@aviobook/_shared';
import classnames from 'classnames';
import { TFontSize } from 'styles';

type TProps = {
  isActiveTab: boolean;
  name: string;
  setTab: () => void;
  textSize?: TFontSize;
};

export const Tab: FC<TProps> = ({ name, isActiveTab, setTab, textSize = 'XL' }) => {
  return (
    <li className={classnames('nav-bar-sub-li', { isActiveTab })} key={name} onClick={setTab}>
      <Text size={textSize} weight="bold">
        {name}
      </Text>
    </li>
  );
};
