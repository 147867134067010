import { FC, PropsWithChildren, ReactNode } from 'react';
import { Text } from '@aviobook/_shared';

import './pageContentBlock.scss';

type TProps = {
  rightButton?: ReactNode;
  title?: string;
};

export const PageContentBlock: FC<PropsWithChildren<TProps>> = ({ rightButton, title, children }) => {
  return (
    <section className="page-content-block-container">
      <div className="page-content-block-top">
        <Text color="aviobookLightPrimaryAlpha" size="XL" weight="regular">
          {title}
        </Text>
        {rightButton}
      </div>
      <div className="page-content-block-content">{children}</div>
    </section>
  );
};
