import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Checkbox, Text } from '@aviobook/_shared';
import { FlexComponent } from '@aviobook/cms/_shared';
import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { TChatbot } from 'types/src/chatbot';

import { useChatbots, useDeleteChatbot } from '../_queries';

export const useChatbotContentTable = () => {
  const { t } = useTranslation();
  const { isLoading, data: chatbots, refetch: refetchChatbots } = useChatbots();
  const data = chatbots?.sort((a, b) => a.name.localeCompare(b.name));

  const [itemsToDelete, setItemsToDelete] = useState<string[]>([]);
  const { deleteChatbots, isLoading: isDeleteChatbotLoading } = useDeleteChatbot();

  const columnHelper = createColumnHelper<TChatbot>();

  const isCheckedAll = () => {
    return data.every(item => itemsToDelete.find(i => i === item.id));
  };
  const setSelectAll = (checked: boolean) => {
    let updatedItems = [];
    if (checked) {
      updatedItems = data.map(item => item.id);
    }
    setItemsToDelete(updatedItems);
  };

  const selectItemToDelete = (id: string, value) => {
    if (value) {
      setItemsToDelete([...itemsToDelete, id]);
    } else {
      setItemsToDelete(itemsToDelete.filter(item => item !== id));
    }
  };

  const isItemChecked = (id: string) => {
    return itemsToDelete.find(item => item === id)?.length > 0;
  };

  const columns = [
    columnHelper.accessor('name', {
      cell: info => (
        <FlexComponent flexDirection="row">
          <Checkbox
            checked={isItemChecked(info.row.original.id)}
            name="select"
            onChange={value => selectItemToDelete(info.row.original.id, value)}
          />
          <Link style={{ width: '90%' }} to={`/admin/connect/general/chatbots/${info.getValue()}?id=${info.row.original.id}`}>
            <Text as="span" color="aviobookCMSLightBlue" size="MD">
              {info.getValue()}
            </Text>
          </Link>
        </FlexComponent>
      ),
      header: () => (
        <FlexComponent backgroundColor="transparent" flexDirection="row">
          <Checkbox checked={isCheckedAll()} name="selectAll" onChange={setSelectAll} />
          <Text as="span" color="aviobookLightPrimaryAlpha" size="XS" uppercase weight="bold" width="partial-width">
            {t('CHATBOTS_COLUMN_NAME')}
          </Text>
        </FlexComponent>
      ),
      id: 'Name',
      size: 10,
    }),
    columnHelper.accessor('description', {
      cell: info => (
        <FlexComponent flexDirection="row">
          <Text as="span" color="aviobookCMSLightBlue" size="MD">
            {info.getValue()}
          </Text>
        </FlexComponent>
      ),
      header: () => t('CHATBOTS_COLUMN_DESCRIPTION'),
      id: 'Description',
    }),
  ];

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
  });

  return {
    chatbots,
    deleteButtonDisabled: itemsToDelete.length === 0,
    deleteChatbots: async () => {
      await deleteChatbots(itemsToDelete);
      await refetchChatbots();
    },
    isDeleteChatbotLoading,
    isLoading,
    table,
  };
};
