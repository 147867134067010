const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const weekdays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

interface DateTimeFormatModel {
  year: string;
  month: string;
  day: string;
  hour: string;
  hourAmerican: string;
  minute: string;
  second: string;
  millisecond: string;
  monthName: string;
  dayName: string;
  amPm: string
}

function formatDate(date: DateTimeFormatModel, arg: string) {
  return arg
    .replace('yyyy', date.year)
    .replace('YYYY', date.year)
    .replace('a', date.amPm)
    .replace('MMMM', date.monthName)
    .replace('MMM', date.monthName.slice(0, 3))
    .replace('MM', date.month)
    .replace('HH', `00${date.hour}`.slice(-2))
    .replace('H', date.hour)
    .replace('hh', `00${date.hourAmerican}`.slice(-2))
    .replace('h', date.hourAmerican)
    .replace('dddd', date.dayName)
    .replace('ddd', date.dayName.slice(0, 3))
    .replace('dd', date.day)
    .replace('DD', date.day)
    .replace('mm', date.minute)
    .replace('ss', date.second)
    .replace('SSS', date.millisecond);
}

export function formatUTC(date: Date, arg: string) {
  const dateObject = {
    year: date.getUTCFullYear().toString(),
    month: `00${String(date.getUTCMonth() + 1)}`.slice(-2),
    day: `00${String(date.getUTCDate())}`.slice(-2),
    hour: String(date.getUTCHours()),
    hourAmerican: String(date.getUTCHours() > 12 ? date.getUTCHours() - 12 : date.getUTCHours()),
    minute: `00${String(date.getUTCMinutes())}`.slice(-2),
    second: `00${String(date.getUTCSeconds())}`.slice(-2),
    millisecond: `000${String(date.getUTCMilliseconds())}`.slice(-3),
    monthName: monthNames[date.getUTCMonth()],
    dayName: weekdays[date.getUTCDay()],
    amPm: date.getUTCHours() > 12 ? 'pm' : 'am',
  };

  return formatDate(dateObject, arg);
}

export function format(date: Date, arg: string) {
  const dateObject = {
    year: date.getFullYear().toString(),
    month: `00${String(date.getMonth() + 1)}`.slice(-2),
    day: `00${String(date.getDate())}`.slice(-2),
    hour: String(date.getHours()),
    hourAmerican: String(date.getHours() > 12 ? date.getHours() - 12 : date.getHours()),
    minute: `00${String(date.getMinutes())}`.slice(-2),
    second: `00${String(date.getSeconds())}`.slice(-2),
    millisecond: `000${String(date.getMilliseconds())}`.slice(-3),
    monthName: monthNames[date.getMonth()],
    dayName: weekdays[date.getDay()],
    amPm: date.getHours() > 12 ? 'pm' : 'am',
  };

  return formatDate(dateObject, arg);
}

export function valueOf(date: Date) {
  return date.getTime();
}

export function toISOString(date: Date) {
  return date.toISOString();
}

export function UTC(date: Date) {
  return new Date(date.toUTCString());
}
