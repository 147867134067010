import { useAuthenticationContext } from '@aviobook/_context';
import { HttpClient, TApiError } from '@aviobook/_http';
import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS, TProfile, TRolePermission } from 'types';

function authenticate(): Promise<TProfile> {
  return HttpClient.get('/api/v1/admin/auth/me');
}

export function useAuthenticateCMS(options?: { enabled?: boolean }) {
  const { enableAuthenticationCMS } = useAuthenticationContext();
  const query = useQuery<TProfile, TApiError>([QUERY_KEYS.AUTHENTICATE_CMS], authenticate, {
    enabled: enableAuthenticationCMS,
    refetchOnMount: false,
    retry: false,
    ...options,
  });
  const hasPermission = (permission: TRolePermission) => {
    return query.data?.permissions.includes(permission);
  };
  return { ...query, hasPermission, isLoading: query.isLoading || query.fetchStatus === 'fetching' };
}
