import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from '@aviobook/_hooks';
import { TFormValidationErrors } from '@aviobook/_hooks/useForm';
import { InputField } from '@aviobook/_shared';
import { formValidator } from '@aviobook/_utils/formValidation';
import { Button, FlexComponent, Modal } from '@aviobook/cms/_shared';
import { Tkeyword } from 'types/src/keyword';

type TProps = {
  closeModal: () => void;
  onsubmit: (keyword: Tkeyword) => void;
};

const initialForm: Tkeyword = {
  description: '',
  keyword: '',
};

function validateForm(values: Tkeyword): TFormValidationErrors<Tkeyword> {
  return {
    description: formValidator.required(values.description),
    keyword: formValidator.required(values.keyword),
  };
}

export const AddKeywordModal: FC<TProps> = ({ onsubmit: submitForm, closeModal }) => {
  const { t } = useTranslation();

  const form = useForm<Tkeyword>({
    initialForm,
    submitForm,
    validateForm,
  });

  return (
    <Modal>
      <Modal.Header onCancel={closeModal}>ADD KEYWORD</Modal.Header>
      <form onSubmit={form.submit}>
        <FlexComponent flexDirection="column" gap={1} padding={2} width="full">
          <InputField label="text" name="keyword" onChange={form.setAttribute} required value={form.values?.keyword} />
          <InputField
            label="description"
            name="description"
            onChange={form.setAttribute}
            required
            value={form.values?.description}
          />
        </FlexComponent>
        <Modal.Buttons>
          <div className="buttons-left-container"></div>
          <Button isOutline onClick={closeModal} theme="transparent">
            {t('SHARED.BUTTONS.CANCEL')}
          </Button>
          <Button disabled={!form.isDirty || form.hasValidationErrors} theme="primary" type="submit">
            ADD
          </Button>
        </Modal.Buttons>
      </form>
    </Modal>
  );
};
