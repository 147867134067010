import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from '@aviobook/_hooks';
import { TFormValidationErrors } from '@aviobook/_hooks/useForm';
import { InputField } from '@aviobook/_shared';
import { formValidator } from '@aviobook/_utils/formValidation';
import { generateUUID } from '@aviobook/_utils/generateUUID';
import { Button, FlexComponent, Modal } from '@aviobook/cms/_shared';
import { TQuickReply } from 'types/src/quickReply';

type TProps = {
  closeModal: () => void;
  isLoading: boolean;
  onAdd(values: TQuickReply): void;
};

const initialForm: TQuickReply = {
  description: '',
  id: generateUUID(),
  isActive: false,
  text: '',
};

function validateForm(values: TQuickReply): TFormValidationErrors<TQuickReply> {
  return {
    description: formValidator.required(values.description),
    text: formValidator.required(values.text),
  };
}

export const AddQuickReplyModal: FC<TProps> = ({ closeModal, onAdd: submitForm, isLoading }) => {
  const { t } = useTranslation();

  const form = useForm<TQuickReply>({
    initialForm,
    submitForm,
    validateForm,
  });
  return (
    <Modal>
      <Modal.Header onCancel={closeModal}>{t('ADD_QUICK_REPLIES.MODAL.TITLE')}</Modal.Header>
      <form onSubmit={form.submit}>
        <FlexComponent flexDirection="column" gap={1} padding={2} width="full">
          <InputField label="text" name="text" onChange={form.setAttribute} required value={form.values?.text} />
          <InputField
            label="description"
            name="description"
            onChange={form.setAttribute}
            required
            value={form.values?.description}
          />
        </FlexComponent>
        <Modal.Buttons>
          <div className="buttons-left-container"></div>
          <Button isOutline onClick={closeModal} theme="transparent">
            {t('SHARED.BUTTONS.CANCEL')}
          </Button>
          <Button disabled={!form.isDirty || form.hasValidationErrors} loading={isLoading} theme="primary" type="submit">
            ADD
          </Button>
        </Modal.Buttons>
      </form>
    </Modal>
  );
};
