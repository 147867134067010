import { AvioDateTime, DATE_TIME_FORMATS } from 'shared/src/utils'

export function formatDate(date: Date, formatString = DATE_TIME_FORMATS.DATE_TIME) {
  return AvioDateTime(date).formatUTC(formatString);
}

export function formatTime(time: string | Date) {
  return formatDate(new Date(time), DATE_TIME_FORMATS.TIME);
}

export function formatISOString(isoString: string, formatString?: string) {
  return formatDate(new Date(isoString), formatString);
}

export function dateFromString(dateString: string, formatString = DATE_TIME_FORMATS.DATE_TIME) {
  return  AvioDateTime().parse(dateString).format(formatString);
}

export function dateFromTime(timeString: string) {
  return dateFromString(timeString, DATE_TIME_FORMATS.TIME);
}

export function dateFromDateAndTime(date: string, time: string) {
  return dateFromString(`${date} ${time}`);
}

export function ISOStringFromDate(date: Date) {
  return AvioDateTime(date).toISOString()
}

export function isAfterDate(date: Date, minDate: Date) {
  return AvioDateTime(date).isAfter(minDate);
}

export function isBeforeDate(date: Date, maxDate: Date) {
  return AvioDateTime(date).isBefore(maxDate);
}
