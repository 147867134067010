import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { Spinner } from '@aviobook/_shared';
import { AddButton, EmptyState, TableLayout } from '@aviobook/cms/_shared';
import { ConfirmationModal } from '@aviobook/cms/_shared/confirmationModal/ConfirmationModal';
import { customModalStyles } from '@aviobook/cms/_shared/modal/ModalUtils';
import { useAuthenticateCMS } from '@aviobook/cms/auth/_queries';

import { useChatbotQuickRepliesContentTable } from './_hooks/useQuickRepliesContentTable';
import { AddQuickReplyModal } from './AddQuickReplyModal';

import './quickReplies.scss';

export const ChatbotQuickReplies: FC = () => {
  const [isOpenAddModal, setOpenAddModal] = useState(false);
  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);
  const { t } = useTranslation();
  const { hasPermission } = useAuthenticateCMS();
  const {
    table,
    isLoading,
    itemsToDelete,
    createQuickReply,
    deleteQuickReplies,
    isLoadingDeleteQuickReplies,
    isLoadingCreateQuickReply,
  } = useChatbotQuickRepliesContentTable();

  if (isLoading) return <Spinner />;

  return (
    <div>
      <Modal
        isOpen={isOpenAddModal || isOpenDeleteModal}
        onRequestClose={() => (isOpenAddModal ? setOpenAddModal(false) : setOpenDeleteModal(false))}
        style={customModalStyles}
      >
        {isOpenAddModal ? (
          <AddQuickReplyModal
            closeModal={() => setOpenAddModal(false)}
            isLoading={isLoadingCreateQuickReply}
            onAdd={async values => {
              await createQuickReply(values);
              setOpenAddModal(false);
            }}
          />
        ) : null}
        {isOpenDeleteModal ? (
          <ConfirmationModal
            cancelButtonAction={() => setOpenDeleteModal(false)}
            cancelButtonLabel={'CANCEL'}
            confirmButtonAction={async () => {
              await deleteQuickReplies();
              setOpenDeleteModal(false);
            }}
            confirmButtonLabel={'Delete'}
            loading={isLoadingDeleteQuickReplies}
            title={`Delete quick ${itemsToDelete?.length > 1 ? 'replies' : 'reply'}?`}
          />
        ) : null}
      </Modal>

      <TableLayout
        addButtonAction={() => setOpenAddModal(true)}
        addButtonLabel={t('QUICK_REPLIES_ADDBUTTON')}
        deleteButtonAction={() => setOpenDeleteModal(true)}
        deleteButtonDisabled={itemsToDelete?.length <= 0}
        emptyState={() => (
          <EmptyState
            button={() =>
              hasPermission('ADD_QUICK_REPLIES') ? (
                <AddButton
                  label={t('QUICK_REPLIES_ADDBUTTON')}
                  onClick={() => {
                    setOpenAddModal(true);
                  }}
                />
              ) : null
            }
            description={t('QUICK_REPLIES_EMPTYSTATE_DESCRIPTION')}
            title={t('QUICK_REPLIES_EMPTYSTATE_TITLE')}
          />
        )}
        isAddButtonPresent={hasPermission('ADD_QUICK_REPLIES')}
        isDeleteButtonPresent={hasPermission('UPDATE_QUICK_REPLIES')}
        table={table}
      />
    </div>
  );
};
