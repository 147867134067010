import React, { FC, MouseEvent, ReactNode } from 'react';
import classNames from 'classnames';

import Icon from '../icon/Icon';
import { Text } from '../text/Text';

import './sideModal.scss';

type TProps = {
  bottomButtons?: ReactNode;
  className?: string;
  content?: ReactNode;
  isVisible?: boolean;
  modalSide?: 'left' | 'right';
  onClose?: () => void;
  title: string;
};

export const SideModal: FC<TProps> = ({ modalSide = 'right', className, title, onClose, bottomButtons, content, isVisible }) => {
  const cssClasses = classNames('side-modal', { 'side-modal-open': isVisible }, modalSide, className);

  if (!isVisible) return null;

  const handleModalBackgroundClick = (event: MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) {
      onClose?.();
    }
  };

  return (
    <div className="side-modal-background" onClick={handleModalBackgroundClick}>
      <aside className={cssClasses}>
        <section className="side-modal-top">
          <Text color="aviobookDarkSecondaryHotel" size="LG" uppercase weight="semiBold">
            {title}
          </Text>
          <div className="side-modal-close-icon">
            <Icon name="closeCross" onClick={onClose} />
          </div>
        </section>
        <section className="side-modal-content">{content}</section>
        <section className="side-modal-bottom">{bottomButtons}</section>
      </aside>
    </div>
  );
};
