import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useBreadcrumbs } from '@aviobook/_hooks';
import { toastError } from '@aviobook/Toastify';

import { useGetRole } from '../../_queries';

export function useRole() {
  const { t } = useTranslation();
  const { roleid } = useParams();
  const updateBreadCrumbs = useBreadcrumbs(state => state.findAndReplace);

  const { data, isLoading, isError, isSuccess } = useGetRole(roleid);

  useEffect(() => {
    if (isSuccess) {
      updateBreadCrumbs(roleid, data.airlineRole);
    }
  }, [isSuccess, data, roleid]);

  if (isError) {
    toastError(t('TOAST_ERROR'));
  }

  return { data, isError, isLoading };
}
