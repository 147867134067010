import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthenticationContext } from '@aviobook/_context';
import { HttpClient, TApiError } from '@aviobook/_http';
import { useMutation } from '@tanstack/react-query';
import { TProfile } from 'types';

function verify(resetToken: string): Promise<TProfile> {
  return HttpClient.post<TProfile>('/api/v1/admin/auth/verify', { resetToken });
}

export function useVerifyCMS() {
  const navigate = useNavigate();
  const { setEnableAuthenticationCMS } = useAuthenticationContext();
  useEffect(() => {
    setEnableAuthenticationCMS(false);
  }, []);

  return useMutation<TProfile, TApiError, string>(['verifyWeb'], verify, {
    onSuccess: async profile => {
      if (profile) {
        setEnableAuthenticationCMS(true);
        navigate('/admin/connect');
      }
    },
  });
}
