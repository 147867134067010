import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Icon, SearchField } from '@aviobook/_shared';

import { useNotificationsContext } from '../../../_context';
import { TRoomArrivalStatus } from '../../../_http';
import { TabBar } from '../tabBar/TabBar';
import { TTabBar } from '../tabBar/TabBar.types';

type TProps = {
  searchQuery: string;
  selectedFlightroomTab: TRoomArrivalStatus.Ongoing | TRoomArrivalStatus.Completed;
  setSearchQuery: (query: string) => void;
  setSelectedFlightroomTab: (roomStatus: TRoomArrivalStatus.Ongoing | TRoomArrivalStatus.Completed) => void;
};

export const FlightRoomsHeaderComponent: FC<TProps> = ({
  searchQuery,
  setSearchQuery,
  setSelectedFlightroomTab,
  selectedFlightroomTab,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { amtUnreadNotifications } = useNotificationsContext();

  // ADD TRANSLATIONS
  const tabs: TTabBar[] = [
    { onClick: () => setSelectedFlightroomTab(TRoomArrivalStatus.Ongoing), text: t('FLIGHTROOMS.SECTION.ACTIVE') },
    { onClick: () => setSelectedFlightroomTab(TRoomArrivalStatus.Completed), text: t('FLIGHTROOMS.SECTION.COMPLETED') },
  ];

  return (
    <header className="flightrooms-header-container">
      <div className="flightrooms-header-top-container">
        <div className="flightrooms-header-top-left" />
        <div className="flightrooms-header-top-right">
          <div className="flightrooms-header-notification-icon">
            {amtUnreadNotifications > 0 && <div className="flightrooms-header-notification-indicator" />}
            <Icon name="notificationBell" onClick={() => navigate('/dashboard/notification-center')} size={2.5} />
          </div>
          <Icon name="profileOutline" onClick={() => navigate('/dashboard/profile')} size={2.5} />
        </div>
      </div>
      <div className="flightrooms-header-search">
        <SearchField search={searchQuery} setSearch={setSearchQuery} />
      </div>
      {!searchQuery ? (
        <div className="flightrooms-header-nav-container">
          <TabBar initialTab={selectedFlightroomTab === TRoomArrivalStatus.Ongoing ? 0 : 1} tabs={tabs} />
        </div>
      ) : null}
    </header>
  );
};
