import { HttpClient, THttpMetadataQuery, THttpSortDirection, TRoomArrivalStatus } from '@aviobook/_http';
import { getUrlWithSearchParams } from '@aviobook/_utils/queryHelpers';
import { useInfiniteQuery } from '@tanstack/react-query';
import { QUERY_KEYS, TFlightroomData } from 'types';

const FLIGHTROOMS_PER_PAGE = 20;

const query: THttpMetadataQuery = {
  roomArrivalStatus: TRoomArrivalStatus.All,
  skip: 0,
  sortBy: 'lastInteractionDate',
  sortDirection: THttpSortDirection.Ascending,
  take: FLIGHTROOMS_PER_PAGE,
};

async function getFlightrooms(roomArrivalStatus: TRoomArrivalStatus, query: THttpMetadataQuery): Promise<TFlightroomData> {
  return HttpClient.get<TFlightroomData>(getUrlWithSearchParams(`/api/v1/rooms/all`, { ...query, roomArrivalStatus }));
}

export function useFlightrooms(roomArrivalStatus: TRoomArrivalStatus = TRoomArrivalStatus.All) {
  return useInfiniteQuery(
    [QUERY_KEYS.GET_FLIGHTROOMS, roomArrivalStatus, query],
    context => {
      return getFlightrooms(roomArrivalStatus, { ...query, ...context.pageParam });
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (!lastPage || !lastPage.meta || pages.length * FLIGHTROOMS_PER_PAGE > lastPage.meta.totalCount) {
          return undefined;
        }
        return { ...query, skip: lastPage.meta.skip + lastPage.meta.count };
      },
    },
  );
}
