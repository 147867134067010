import { HttpClient, TApiError } from '@aviobook/_http';
import { queryClient } from '@aviobook/index';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { QUERY_KEYS } from 'types';

type TRequestAccessPayload = {
  canJoinFlightRooms: boolean;
  roomId: string;
};

function requestAccess(payload: TRequestAccessPayload): Promise<void> {
  return HttpClient.post<void>('/api/v1/rooms/request-access', payload);
}

export function useRequestAccess(searchQuery: string, options?: UseMutationOptions<void, TApiError, TRequestAccessPayload>) {
  return useMutation<void, TApiError, TRequestAccessPayload>(['requestAccess'], requestAccess, {
    ...options,
    onSuccess: async () => {
      await queryClient.invalidateQueries(['getAllFlightrooms']);
      await queryClient.invalidateQueries([QUERY_KEYS.GET_ADDABLE_FLIGHTROOMS]);
    },
  });
}
