import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from '@aviobook/_hooks';
import { TFormValidationErrors } from '@aviobook/_hooks/useForm';
import { InputField } from '@aviobook/_shared';
import { formValidator } from '@aviobook/_utils/formValidation';
import { Button, FlexComponent, Modal } from '@aviobook/cms/_shared';
import { TRoleQuickReplies } from 'types';

type TProps = {
  closeModal: () => void;
  isLoading: boolean;
  onAdd(values: TRoleQuickReplies): void;
};

const initialForm: TRoleQuickReplies = {
  description: '',
  value: '',
};

function validateForm(values: TRoleQuickReplies): TFormValidationErrors<TRoleQuickReplies> {
  return {
    description: formValidator.required(values.description),
    value: formValidator.required(values.value),
  };
}

export const AddRoleQuickRepliesModal: FC<TProps> = ({ closeModal, onAdd, isLoading }) => {
  const { t } = useTranslation();

  const form = useForm<TRoleQuickReplies>({
    initialForm,
    submitForm: values => {
      onAdd(values);
    },
    validateForm: values => validateForm(values),
  });

  return (
    <Modal>
      <Modal.Header onCancel={closeModal}>{t('ADD_QUICK_REPLIES.MODAL.TITLE')}</Modal.Header>
      <form onSubmit={form.submit}>
        <FlexComponent flexDirection="column" gap={1} padding={2} width="full">
          <InputField label="text" name="value" onChange={form.setAttribute} required value={form.values?.value} />
          <InputField
            label="description"
            name="description"
            onChange={form.setAttribute}
            required
            value={form.values?.description}
          />
        </FlexComponent>
        <Modal.Buttons>
          <div className="buttons-left-container"></div>
          <Button isOutline onClick={closeModal} theme="neutral">
            {t('SHARED.BUTTONS.CANCEL')}
          </Button>
          <Button loading={isLoading} theme="primary" type="submit">
            ADD
          </Button>
        </Modal.Buttons>
      </form>
    </Modal>
  );
};
