import LinkifyIt from 'linkify-it';
import tlds from 'tlds';

const linkify = new LinkifyIt();
linkify.tlds(tlds);

const PHONE_NUMBER_REGEX = /\b(?:\+?\d{1,3}[-.\s]?)?(?:\(\d{1,}\)|\d{4,})\b/g;

export const matchDecorator = (text: string) => {
  const phoneNumbers: string[] = text.match(PHONE_NUMBER_REGEX) || [];
  const defaultMatches = linkify.match(text) || [];

  const phoneNumberMatches = phoneNumbers.map(match => ({
    index: text.indexOf(match),
    lastIndex: text.indexOf(match) + match.length,
    schema: '',
    text: match,
    url: `tel:${match}`,
  }));

  return [...phoneNumberMatches, ...defaultMatches];
};
