import DOMPurify from 'dompurify';
import { create } from 'zustand';

interface BreadcrumbsState {
  crumbs: string[];
}

interface BreadcrumbsActions {
  add: (input: string) => void;
  findAndReplace: (needle: string, replacement: string) => void;
  init: (input: string[]) => void;
  remove: (index: number) => void;
}

export function parseCrumbs(input: string[]): string[] {
  return input
    .map(DOMPurify.sanitize)
    .map(String)
    .filter((fragment: string) => fragment?.length > 0);
}

const useBreadcrumbs = create<BreadcrumbsState & BreadcrumbsActions>()(set => {
  return {
    add: (input: string) => set(({ crumbs }) => ({ crumbs: crumbs.concat(input) })),
    crumbs: [],
    findAndReplace: (needle, replacement) =>
      set(({ crumbs }) => {
        const index = crumbs.findIndex(crumb => crumb.toLowerCase() === needle.toLowerCase());

        if (index >= 0) {
          crumbs.splice(index, 1, replacement);
        }

        return { crumbs };
      }),
    init: input => set(() => ({ crumbs: input })),
    remove: (index: number) =>
      set(({ crumbs }) => {
        if (crumbs.at(index)) {
          const newcrumbs = [...crumbs];

          newcrumbs.splice(index, 1);
          return { crumbs: newcrumbs };
        }

        return { crumbs };
      }),
  };
});

export default useBreadcrumbs;
