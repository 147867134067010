import { FC } from 'react';
import { MessageProps } from 'react-native-gifted-chat';
import { formatTime, TGiftedMessage } from 'shared';

import { MessageBubble, SystemMessage, UnreadIndicator } from '../index';

type TProps = {
  firstUnreadMessage: string;
  message: MessageProps<TGiftedMessage>;
  onSend: (message: string, attachment?: string) => void;
  setAttachmentPreview: (attachment: string) => void;
  userId: string;
};

export const GiftedChatMessage: FC<TProps> = ({ firstUnreadMessage, message, userId, onSend, setAttachmentPreview }) => {
  const renderUnreadIndicator =
    firstUnreadMessage === message.currentMessage.messageId && message.currentMessage.user._id !== userId;
  return (
    <>
      {message.currentMessage.isSystemMessage ? (
        <SystemMessage
          key={message.currentMessage._id}
          level={message.currentMessage.systemMessageLevel}
          message={message.currentMessage.text}
          time={formatTime(new Date(message.currentMessage.createdAt))}
        />
      ) : (
        <MessageBubble
          {...message}
          onClickAttachment={setAttachmentPreview}
          onRetrySend={onSend}
          userId={userId}
          // TODO: turn this back on when tagging should be in fe
          // taggableRoles={taggableRoles}
        />
      )}

      {renderUnreadIndicator ? <UnreadIndicator /> : null}
    </>
  );
};
