import { useCallback, useEffect, useMemo, useState } from 'react';
import { mapMessageToGiftedChatMessage, TGiftedMessage } from 'shared';
import { QUERY_KEYS, TMessage, TMessagePayload } from 'types';

import { useOfflineMessagesContext } from '../../_context';
import { chatSocket, personalInfoSocket } from '../../_utils/socket';
import { useUpdateLastInteraction } from '../../flightrooms/_queries';
import { queryClient } from '../../index';
import { useMessages } from '../_queries';

type TType = {
  roomId: string;
};
const MESSAGES_PER_PAGE = 20;

export const useChatSocket = ({ roomId }: TType) => {
  const [firstUnreadMessage, setFirstUnreadMessage] = useState<string>();

  const [socketMessages, setSocketMessages] = useState<TGiftedMessage[]>([]);
  const { getStoredMessagesByRoomId, storeMessage } = useOfflineMessagesContext();
  const { mutate: updateLastInteraction } = useUpdateLastInteraction();

  const {
    data,
    refetch: refetchMessages,
    hasNextPage,
    fetchNextPage,
    isFetching: isFetchingMessages,
    isLoading,
    isRefetching,
    status,
  } = useMessages(roomId, { take: MESSAGES_PER_PAGE }, { enabled: false });

  // We get 'status success' like this because of the default 'isSuccess' from react-query not triggering
  const isSuccess = status === 'success';
  const storedMessages = getStoredMessagesByRoomId(roomId);
  const messages = data?.pages.reduce((messages, page) => [...messages, ...page.data], []) || [];
  const lastReadDate = useMemo(
    () => data?.pages && new Date(data.pages[0].meta.lastInteractionDate),
    [data?.pages[0].meta.lastInteractionDate],
  );
  const mappedMessages = [...storedMessages, ...messages]
    .sort((a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime())
    .filter((message, index, self) => index === self.findIndex(m => m.messageId === message.messageId))
    .map(mapMessageToGiftedChatMessage);

  const storePendingMessage = useCallback(
    (message: TMessagePayload) => {
      storeMessage({ ...message, roomId });
    },
    [roomId],
  );

  useEffect(() => {
    const handleSocketMessage = (message: TMessage) => {
      const giftedMessage = mapMessageToGiftedChatMessage(message);
      setSocketMessages(prev => [giftedMessage, ...prev]);
      setFirstUnreadMessage(undefined);
    };

    if (socketMessages && socketMessages.length > 0) {
      setSocketMessages([]);
    }
    refetchMessages();

    chatSocket.on(`message-${roomId}`, handleSocketMessage);
    personalInfoSocket.on(`message-${roomId}`, handleSocketMessage);
    chatSocket.on(`message-error-stream`, storePendingMessage);

    return () => {
      chatSocket.off(`message-${roomId}`);
      personalInfoSocket.off(`message-${roomId}`);
      chatSocket.off(`message-error-stream`);
      updateLastInteraction(roomId);
      // when leaving the room you have to reset the unmount for the message
      queryClient.invalidateQueries([QUERY_KEYS.GET_FLIGHTROOMS]);
      queryClient.invalidateQueries([QUERY_KEYS.GET_ADDABLE_FLIGHTROOMS]);
    };
  }, [roomId]);

  return {
    data,
    fetchNextPage,
    firstUnreadMessage,
    hasNextPage,
    isFetchingMessages,
    isLoading,
    isRefetching,
    isSuccess,
    lastReadDate,
    mappedMessages,
    messages,
    setFirstUnreadMessage,
    socketMessages,
    storePendingMessage,
  };
};
