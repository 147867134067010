// This is a simplified implementation
// of https://youmightnotneed.com/lodash#unionBy
// which would work with only one array
export const uniqBy = (arr: any[], iteratee: any) => {
  if (typeof iteratee === 'string') {
    const prop = iteratee;
    iteratee = item => item[prop];
  }

  return arr.filter((x, i, self) => i === self.findIndex(y => iteratee(x) === iteratee(y)));
};
