import React, { createContext, FC, ReactNode, useContext, useState } from 'react';
import { STATUS, TCrewMember } from 'types';

type TOnlineIndicatorChange = {
  onlineIndicator: STATUS;
  userId: string;
};

type TUsersStatusContext = {
  getStatus: (userId: string) => STATUS;
  setCrewMembersStatus: (crew: TCrewMember[]) => void;
  updateStatus: (payload: TOnlineIndicatorChange) => void;
};

const UsersStatusContext = createContext<TUsersStatusContext>({
  getStatus: () => STATUS.notConnected,
  setCrewMembersStatus: () => {},
  updateStatus: () => {},
});

export const useUsersStatusContext = () => useContext(UsersStatusContext);

type TProps = {
  children: ReactNode;
};

export const UsersStatusContextProvider: FC<TProps> = ({ children }) => {
  const [onlineIndicators, setOnlineIndicators] = useState<Record<string, STATUS>>({});

  return (
    <UsersStatusContext.Provider
      value={{
        getStatus: userId => onlineIndicators[userId],
        setCrewMembersStatus: crew => {
          const crewMembersStatus = crew.reduce((acc, crewMember) => {
            acc[crewMember.id] = crewMember.onlineIndicator;
            return acc;
          }, {} as Record<string, STATUS>);
          setOnlineIndicators(prevState => ({ ...prevState, ...crewMembersStatus }));
        },
        updateStatus: ({ userId, onlineIndicator }) =>
          setOnlineIndicators(prevState => ({ ...prevState, [userId]: onlineIndicator })),
      }}
    >
      {children}
    </UsersStatusContext.Provider>
  );
};
