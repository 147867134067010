import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Checkbox, Text } from '@aviobook/_shared';
import { FlexComponent } from '@aviobook/cms/_shared';
import { useGetRole, useUpdateRole } from '@aviobook/cms/connect/_screens/roles/_queries';
import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import {
  ApiKeysPermission,
  ChatbotPermission,
  flightRoomPermission,
  GeneralPermission,
  QuickRepliesPermission,
  RolesPermission,
  TPermission,
  TRolePermission,
} from 'types';

type TTitlePermissions = 'Flightrooms' | 'Chatbots' | 'General' | 'Api keys' | 'Roles' | 'QuickReplies';

const FLIGHTROOMS_PERMISSIONS: flightRoomPermission[] = [
  'VIEW_FLIGHTROOMS',
  'JOIN_FLIGHTROOMS',
  'REQUEST_ACCESS_FLIGHTROOMS',
  'GRANT_ACCESS_FLIGHTROOMS',
];
const CHATBOT_PERMISSIONS: ChatbotPermission[] = ['VIEW_CHATBOTS', 'ADD_CHATBOTS', 'UPDATE_CHATBOTS', 'DELETE_CHATBOTS'];
const GENERAL_PERMISSIONS: GeneralPermission[] = ['UPDATE_ORGANIZATION_SETTINGS'];
const API_KEYS_PERMISSIONS: ApiKeysPermission[] = ['VIEW_API_KEYS', 'ADD_API_KEYS', 'UPDATE_API_KEYS', 'DELETE_API_KEYS'];
const ROLES_PERMISSIONS: RolesPermission[] = ['VIEW_ROLES', 'UPDATE_ROLES'];
const QuickReplies_PERMISSIONS: QuickRepliesPermission[] = [
  'VIEW_QUICK_REPLIES',
  'ADD_QUICK_REPLIES',
  'UPDATE_QUICK_REPLIES',
  'DELETE_QUICK_REPLIES',
];

const data: TPermission[] = [
  {
    permissions: FLIGHTROOMS_PERMISSIONS,
    title: 'Flightrooms',
  },
  {
    permissions: CHATBOT_PERMISSIONS,
    title: 'Chatbots',
  },
  {
    permissions: GENERAL_PERMISSIONS,
    title: 'General',
  },
  {
    permissions: API_KEYS_PERMISSIONS,
    title: 'Api keys',
  },
  {
    permissions: ROLES_PERMISSIONS,
    title: 'Roles',
  },
  {
    permissions: QuickReplies_PERMISSIONS,
    title: 'QuickReplies',
  },
];

const allPermissions: TRolePermission[] = [
  ...FLIGHTROOMS_PERMISSIONS,
  ...CHATBOT_PERMISSIONS,
  ...GENERAL_PERMISSIONS,
  ...QuickReplies_PERMISSIONS,
  ...ROLES_PERMISSIONS,
  ...API_KEYS_PERMISSIONS,
];

export const useRolePermissionsContentTable = () => {
  const { t } = useTranslation();
  const { roleid } = useParams();

  const columnHelper = createColumnHelper<TPermission>();

  const { mutate: updateRole } = useUpdateRole();

  const { data: roleData, isLoading } = useGetRole(roleid);
  const [selectedPermissions, setSelectedPermissions] = useState<TRolePermission[]>(roleData?.permissions);
  const [isDirty, setIsDirty] = useState(false);

  const updatePermissions = async () => {
    if (isDirty) {
      updateRole({ ...roleData, permissions: selectedPermissions });
      setIsDirty(false);
    }
  };

  const isRowPermissionChecked = (row: TTitlePermissions) => {
    const rowPermissions = data.filter(d => d.title === row).pop().permissions;
    return (
      rowPermissions?.map(p => selectedPermissions.some(selectedPermission => selectedPermission === p)).filter(p => !p)
        .length === 0
    );
  };

  const addPermissions = (permissions: TRolePermission[]) => {
    const set = new Set(selectedPermissions);
    permissions.forEach(permission => set.add(permission));
    setSelectedPermissions(Array.from(set));
  };

  const checkRow = (row: TTitlePermissions, value: boolean) => {
    const rowPermissions = data.filter(d => d.title === row).pop().permissions;
    if (!isDirty) {
      setIsDirty(true);
    }
    if (value) {
      addPermissions(rowPermissions);
    } else {
      setSelectedPermissions(selectedPermissions.filter(p => !rowPermissions.some(rowPermission => rowPermission === p)));
    }
  };

  const isPermissionChecked = (value: string) => {
    return selectedPermissions.find(permission => permission === value)?.length > 0;
  };

  const onCheckPermission = (permission: TRolePermission, value: boolean) => {
    if (!isDirty) {
      setIsDirty(true);
    }
    if (value) {
      addPermissions([permission]);
    } else {
      setSelectedPermissions(selectedPermissions.filter(p => p !== permission));
    }
  };

  const areAllPermissionChecked = () => {
    return selectedPermissions.length >= allPermissions.length;
  };

  const checkAll = value => {
    if (value) {
      setSelectedPermissions(allPermissions);
      setIsDirty(true);
    } else {
      setSelectedPermissions([]);
      setIsDirty(false);
    }
  };

  const columns = [
    columnHelper.accessor('title', {
      cell: info => (
        <FlexComponent flexDirection="row">
          <Checkbox
            checked={isRowPermissionChecked(info.getValue())}
            name="quickReply"
            onChange={value => {
              checkRow(info.getValue(), value);
            }}
          />
          <Text as="span" color="aviobookCMSLightBlue" size="MD" width="partial-width">
            {info.getValue()}
          </Text>
        </FlexComponent>
      ),
      header: () => (
        <FlexComponent backgroundColor="transparent" flexDirection="row">
          <Checkbox checked={areAllPermissionChecked()} name="quickReplies" onChange={checkAll} />
          <Text as="span" color="aviobookLightPrimaryAlpha" size="XS" uppercase weight="bold" width="partial-width">
            {t('ROLES.PERMISSIONS.TABLE.HEADER.TITLE')}
          </Text>
        </FlexComponent>
      ),
      id: 'KEYWORDS',
      size: 10,
    }),
    columnHelper.accessor('permissions', {
      cell: info => (
        <FlexComponent flexDirection="row" width="auto">
          {info.getValue().map(permission => (
            <div key={permission} style={{ display: 'flex', width: '25%' }}>
              <Checkbox
                checked={isPermissionChecked(permission)}
                name="quickReply"
                onChange={value => onCheckPermission(permission, value)}
              />
              <Text as="span" color="aviobookCMSLightBlue" size="MD" width="partial-width">
                {permission}
              </Text>
            </div>
          ))}
        </FlexComponent>
      ),
      header: () => t('ROLES.PERMISSIONS.TABLE.HEADER.PERMISSIONS'),
      id: 'permissions',
    }),
  ];

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
  });

  return {
    isLoading,
    isSaveButtonDisabled: !isDirty,
    table,
    updatePermissions,
  };
};
