import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AvioDateTime, useFlightroomsContext } from 'shared';
import { TNotification } from 'types';

import { Text } from '../../../_shared';
import { useGetFlightroom } from '../../../flightrooms/_queries';

import './notificationCenterItem.scss';

type TProps = {
  notification: TNotification;
  setNotificationsRead: (roomId: string) => void;
};

export const NotificationCenterItem: FC<TProps> = ({ notification, setNotificationsRead }) => {
  const { openRoom, selectedFlightroomId } = useFlightroomsContext();
  const { data: flightroom, isLoading: isLoadingFlightroom } = useGetFlightroom(notification.flightRoomId);
  const { t } = useTranslation();

  const handleOpenFlightroom = () => {
    setNotificationsRead(notification.flightRoomId);
    if (selectedFlightroomId != notification.flightRoomId && !isLoadingFlightroom) {
      openRoom(flightroom);
    }
  };

  return (
    <button className="notification-center-item-container" onClick={handleOpenFlightroom}>
      <div className="notification-center-item-status">
        {!notification.hasSeen && <div className="notification-center-item-status-dot" />}
      </div>
      <div className="notification-center-item-text-container">
        <Text color={!notification.hasSeen ? 'aviobookWebPrimaryZulu08' : 'aviobookWebPrimaryZulu05'} size="SM" weight="semiBold">
          {`${t('NOTIFICATION_CENTER.NOTIFICATION_ITEM.MESSAGE', {
            flightRoomName: notification.flightRoomName,
            userInfo: notification.userInfo,
          })}`}
        </Text>
        <Text className="notification-center-item-notification-created-at" color="aviobookWebPrimaryZulu05" size="XXS">
          { 
            AvioDateTime().diff(AvioDateTime().parse(notification.createdAt).value)
          }
        </Text>
      </div>
    </button>
  );
};
