import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button, Icon, Text } from '../_shared';
import { useAuthenticate, useLogout } from '../auth/_queries';
import ConfirmationModal from '../modal/confirmation/ConfirmationModal';
import { ModalOpener } from '../modal/ModalOpener';

import './profile.scss';

export const Profile: FC = () => {
  const { t } = useTranslation();
  const { data } = useAuthenticate();
  const { email, jobTitle, phoneNumber, username } = data || {};
  const navigate = useNavigate();
  const { mutate: logout } = useLogout();

  const onClickLogout = () => {
    ModalOpener.instance.open({
      render: () => (
        <ConfirmationModal
          confirmText={t('AUTH.LOGOUT')}
          content={t('AUTH.LOGOUT_ALERT_REDIRECT')}
          onConfirm={logout}
          title={t('AUTH.LOGOUT')}
        />
      ),
    });
  };

  return (
    <aside className="profile-container">
      <header className="profile-section-top-container">
        <div className="profile-section-top-header">
          {/* 
            // TODO: add this back once we have bot settings.
            <Icon colorName="aviobookWebPrimaryZulu08" name="gear" /> 
          */}
          <span className="profile-section-top-header-left-spacer" />
          <Text color="aviobookWebPrimaryZulu08" size="MD" uppercase weight="bold">
            {t('PROFILE.HEADER_TITLE')}
          </Text>
          <Icon colorName="aviobookWebPrimaryZulu08" name="closeCross" onClick={() => navigate(-1)} />
        </div>
        <div className="profile-section-top-body">
          <div className="profile-icon-container">
            <Icon colorName="coreWhite100" name="profileOutline" size={6} />
          </div>
          <Text color="aviobookWebPrimaryZulu08" size="MD" weight="semiBold">
            {username}
          </Text>
          <Text color="aviobookWebAccentRomeo07" size="MD" uppercase weight="bold">
            {jobTitle}
          </Text>
        </div>
      </header>
      <div className="profile-mid-bot-container">
        <section className="profile-section-mid-container">
          <div className="profile-section-mid-list-item">
            <Icon
              className="profile-section-mid-list-item-icon"
              colorName="aviobookWebAccentRomeo07"
              name="briefcase"
              size={2.5}
            />
            <div>
              <Text color="aviobookWebAccentRomeo07" size="SM" uppercase weight="bold">
                {t('PROFILE.JOB_TITLE')}
              </Text>
              <Text color="colorsAviobookDarkPrimaryCharlie290Fog" size="SM">
                {jobTitle}
              </Text>
            </div>
          </div>
          <div className="profile-section-mid-list-item">
            <Icon className="profile-section-mid-list-item-icon" colorName="aviobookWebAccentRomeo07" name="phone" size={2.5} />
            <div>
              <Text color="aviobookWebAccentRomeo07" size="SM" uppercase weight="bold">
                {t('PROFILE.PHONE_NUMBER')}
              </Text>
              <Text color="colorsAviobookDarkPrimaryCharlie290Fog" size="SM">
                {phoneNumber}
              </Text>
            </div>
          </div>
          <div className="profile-section-mid-list-item">
            <Icon className="profile-section-mid-list-item-icon" colorName="aviobookWebAccentRomeo07" name="email" size={2.5} />
            <div>
              <Text color="aviobookWebAccentRomeo07" size="SM" uppercase weight="bold">
                {t('PROFILE.EMAIL')}
              </Text>
              <Text color="colorsAviobookDarkPrimaryCharlie290Fog" size="SM">
                {email}
              </Text>
            </div>
          </div>
        </section>
        <footer className="profile-section-bot-container">
          <Button isOutline onClick={() => navigate('/dashboard/about')} theme="dark">
            {t('PROFILE.ABOUT')}
          </Button>
          {/* 
          // TODO: add back 'Feedback' when Aviobook BE is ready to support feedback
          <Button isOutline onClick={() => navigate('/dashboard/feedback')} theme="dark">
            {t('PROFILE.FEEDBACK')}
          </Button> 
          */}
          <Button onClick={onClickLogout}>{t('AUTH.LOGOUT')}</Button>
        </footer>
      </div>
    </aside>
  );
};
