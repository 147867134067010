import { HttpClient, TApiError } from '@aviobook/_http';
import { useQuery } from '@tanstack/react-query';
import { TRole } from 'types';

function getRole(roleId: string): Promise<TRole> {
  return HttpClient.get<TRole>(`/api/v1/admin/roles/${roleId}`);
}

export function useGetRole(roleId: string) {
  return useQuery<TRole, TApiError>(['role', roleId], () => getRole(roleId));
}
