import React, { FC } from 'react';
import { TIconName } from 'assets';

import Icon from '../icon/Icon';

import './avatar.scss';

type TSize = 'small' | 'medium' | 'large' | number;

type TProps = {
  icon: TIconName | string;
  isDisabled?: boolean;
  onPress?: () => void;
  size?: TSize;
};

export const Avatar: FC<TProps> = ({ isDisabled = false, size = 'medium', onPress, icon }) => {
  const getAvatarSize = (): number => {
    if (typeof size === 'number') return size;

    switch (size) {
      case 'large':
        return 13.2;
      case 'medium':
        return 3.7;
      case 'small':
        return 2.8;
      default:
        return 5;
    }
  };

  const getAvatarIconSize = (): number => {
    if (typeof size === 'number') return size * 0.55;

    switch (size) {
      case 'large':
        return 6;
      case 'medium':
        return 2.3;
      case 'small':
        return 1.25;
      default:
        return 2.3;
    }
  };

  const style = {
    height: `${getAvatarSize()}rem`,
    width: `${getAvatarSize()}rem`,
  };

  return (
    <button className="avatar" disabled={isDisabled} onClick={onPress} style={style}>
      <Icon colorName="coreWhite100" name={icon} size={getAvatarIconSize()} />
    </button>
  );
};
