import { HttpClient, TApiError } from "@aviobook/_http";
import { queryClient } from "@aviobook/index";
import { useMutation } from '@tanstack/react-query';

function logout(): Promise<void> {
  return HttpClient.post('/api/v1/auth/logout');
}

export function useLogout() {
  return useMutation<void, TApiError>(['logout'], logout, {
    onSettled: () => {
      queryClient.clear();
    },
  });
}
