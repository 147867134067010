import { HttpClient, TApiError } from '@aviobook/_http';
import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS, TRole } from 'types';

function getRoles(): Promise<TRole[]> {
  return HttpClient.get<TRole[]>('/api/v1/admin/roles');
}

export function useGetRoles() {
  return useQuery<TRole[], TApiError>([QUERY_KEYS.ROLES], getRoles);
}
