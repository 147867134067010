import { TIconName } from 'assets';
import { ROLE } from 'types';
import { SYSTEM_MESSAGE_LEVEL } from 'types';

export const ICON_ROLE_MAPPING: Record<ROLE, TIconName> = {
  [ROLE.ACM]: 'rolePilotCapOutline',
  [ROLE.ADMIN]: 'profileOutline',
  [ROLE.CCM]: 'rolePilotCapOutline',
  [ROLE.COMM]: 'rolePilotCapOutline',
  [ROLE.COPILOT]: 'rolePilotCapOutline',
  [ROLE.PILOT]: 'rolePilotCapOutline',
  [ROLE.DEADHEAD]: 'profileOutline',
  [ROLE.DISPATCHER]: 'roleDispatcherHeadsetOutline',
  [ROLE.GROUND_HANDLER]: 'roleDispatcherHeadsetOutline',
  [ROLE.GUEST]: 'profileOutline',
  [ROLE.MAINTENANCE_CREW]: 'roleMaintenanceGogglesOutline',
  [ROLE.CABIN_CREW]: 'seatbeltOutline',
  [ROLE.SCCM]: 'seatbeltOutline',
  [ROLE.UNKNOWN]: 'profileOutline',
};

export const getIconForRole = (role: ROLE): TIconName => {
  if (!(<any>Object).values(ROLE).includes(role)) {
    return 'profileOutline';
  }
  return ICON_ROLE_MAPPING[role];
};

// TODO: rename all icons to be similar between web/mobile.
export const getIconForSystemMessage = (systemMessageLevel: SYSTEM_MESSAGE_LEVEL): TIconName => {
  switch (systemMessageLevel) {
    case SYSTEM_MESSAGE_LEVEL.ATTENTION:
    case SYSTEM_MESSAGE_LEVEL.CAUTION:
    case SYSTEM_MESSAGE_LEVEL.WARNING:
      return 'triangleCoreCaution';
    case SYSTEM_MESSAGE_LEVEL.UNCLEAR:
    case SYSTEM_MESSAGE_LEVEL.IMPORTED:
    case SYSTEM_MESSAGE_LEVEL.UNKNOWN:
      return 'circularInfoOutline';
    case SYSTEM_MESSAGE_LEVEL.OK:
      return 'check';
  }
};
