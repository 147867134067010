import { FC, useMemo } from 'react';
import classNames from 'classnames';
import { useFlightroomsContext } from 'shared';
import { TFlightroom } from 'types';

import { TRoomArrivalStatus } from '../../_http';
import { Spinner } from '../../_shared';
import { useFlightrooms } from '../_queries';
import { FlightroomItem } from './flightroomitem/FlightroomItem';

type TProps = {
  selectedFlightroomTab: TRoomArrivalStatus.Completed | TRoomArrivalStatus.Ongoing;
};

export const FlightRoomList: FC<TProps> = ({ selectedFlightroomTab }) => {
  const { openRoom, selectedRoom } = useFlightroomsContext();

  const { isLoading, data, fetchNextPage, isFetchingNextPage, hasNextPage } = useFlightrooms(selectedFlightroomTab);

  const { myFlightrooms } = useMemo(() => {
    function removeDuplicateIds(flightrooms: TFlightroom[]) {
      const unique = {};
      flightrooms.forEach(flightroom => {
        if (!unique[flightroom.id]) {
          unique[flightroom.id] = flightroom;
        }
      });
      return Object.values(unique) as TFlightroom[];
    }
    const flatMap: TFlightroom[] = data?.pages?.reduce((flightrooms, page) => [...flightrooms, ...page.data], []) || [];
    const uniqueFlightrooms: TFlightroom[] = removeDuplicateIds(flatMap);
    const myFlightrooms = uniqueFlightrooms.filter(room => room.isMemberOfRoom || room.isLocked);

    return {
      myFlightrooms,
    };
  }, [data?.pages, selectedRoom]);

  const onClickFlightroom = (room: TFlightroom) => {
    if (room.isLocked) return;
    openRoom(room);
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.currentTarget;
    if (target.scrollHeight - target.scrollTop === target.clientHeight && !isFetchingNextPage && hasNextPage) {
      fetchNextPage();
    }
  };

  if (isLoading) {
    return (
      <section className={classNames('flightrooms-content-container', { 'flightrooms-content-container-loader': isLoading })}>
        <Spinner size="large" />
      </section>
    );
  }

  return (
    <section className="flightrooms-content-container" onScroll={handleScroll}>
      {myFlightrooms.map((room, index) => (
        <FlightroomItem
          alternativeColor={index % 2 === 1}
          flightroom={room as TFlightroom}
          id={room.id}
          isSelected={room.id === selectedRoom?.id}
          key={`filtered-${room.id}`}
          onClick={onClickFlightroom}
        />
      ))}

      {isFetchingNextPage ? (
        <span className="flightrooms-content-fetching-spinner">
          <Spinner size="normal" />
        </span>
      ) : null}
    </section>
  );
};
