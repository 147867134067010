import { IMessage, User } from 'react-native-gifted-chat';
import { ROLE, TMessage, TMessagePayload } from 'types';

export type TGiftedMessage = IMessage & Partial<TMessagePayload | TMessage> & { user: User & { jobTitle?: ROLE } };

export const mapMessageToGiftedChatMessage = (
  message: Partial<(TMessagePayload | TMessage) & { isPending: boolean }>,
): TGiftedMessage => ({
  ...message,
  _id: message.messageId ?? '',
  createdAt: message.createdDate ?? new Date(),
  image: message.attachment,
  pending: !!message.isPending,
  text: message.message ?? '',
  system: message.isSystemMessage,
  user: {
    _id: message.senderId ?? message.senderInfo?.userId ?? '',
    jobTitle: message.senderInfo?.jobTitle,
    name: message.senderInfo?.username ?? '',
  },
});
