import { useState } from 'react';

import { TRoomArrivalStatus } from '../_http';
import { FlightRoomList } from './_components/FlightRoomList';
import { FlightRoomsHeaderComponent } from './_components/FlightRoomsHeaderComponent/FlightRoomsHeaderComponent';
import { SearchedFlightRoomList } from './_components/SearchedFlightRoomList';

import './flightrooms.scss';

export const Flightrooms = () => {
  const [selectedFlightroomTab, setSelectedFlightroomTab] = useState<TRoomArrivalStatus.Ongoing | TRoomArrivalStatus.Completed>(
    TRoomArrivalStatus.Ongoing,
  );
  const [searchQuery, setSearchQuery] = useState<string>('');

  // const { isOnline } = useNetworkInfo();

  return (
    <aside className="flightrooms-container">
      <FlightRoomsHeaderComponent
        searchQuery={searchQuery}
        selectedFlightroomTab={selectedFlightroomTab}
        setSearchQuery={setSearchQuery}
        setSelectedFlightroomTab={setSelectedFlightroomTab}
      />
      {searchQuery ? (
        <SearchedFlightRoomList searchQuery={searchQuery} />
      ) : (
        <FlightRoomList selectedFlightroomTab={selectedFlightroomTab} />
      )}
    </aside>
  );
};
