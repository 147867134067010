import React, { createContext, FC, ReactNode, useContext, useState, useMemo, useCallback } from 'react';
import { TFlightroom, TFlightRoomDataWithoutUserInfo, TFlightroomPersonalInfo } from 'types';
import { uniqBy } from '../utils';

type TFlightroomsContext = {
  addFlightroom: (flightroom: TFlightRoomDataWithoutUserInfo) => void;
  deleteFlightroom: (id: string) => void;
  getFlightroomById: (id: string) => TFlightroom | undefined;
  getFlightroomsByIds: (ids: string[]) => TFlightroom[];
  allFlightrooms: TFlightroom[];
  myFlightrooms: TFlightroom[];
  openFlightroom: (id: string) => void;
  openRoom: (room?: TFlightroom) => void;
  deleteRoom: () => void;
  resetFlightroomsContext: () => void;
  selectedFlightroomId?: string;
  selectedRoom?: TFlightroom;
  setFlightrooms: (flightrooms: TFlightroom[]) => void;
  updateFlightroom: (flightroomUpdate: TFlightRoomDataWithoutUserInfo | TFlightroomPersonalInfo) => void;
};

const FlightroomsContext = createContext<TFlightroomsContext>({
  addFlightroom: () => {},
  deleteFlightroom: () => {},
  getFlightroomById: () => undefined,
  getFlightroomsByIds: () => [],
  allFlightrooms: [],
  myFlightrooms: [],
  openFlightroom: () => {},
  resetFlightroomsContext: () => {},
  selectedFlightroomId: '',
  setFlightrooms: () => {},
  updateFlightroom: () => {},
  selectedRoom: undefined,
  openRoom: () => {},
  deleteRoom: () => {},
});

export const useFlightroomsContext = () => useContext(FlightroomsContext);

type TProps = {
  children: ReactNode;
};

export const FlightroomsContextProvider: FC<TProps> = ({ children }) => {
  const [flightrooms, setFlightrooms] = useState<TFlightroom[]>([]);
  const [selectedFlightroomId, setSelectedFlightroomId] = useState<string>();
  const [selectedRoom, setSelectedRoom] = useState<TFlightroom>();
  const myFlightrooms = useMemo(() => flightrooms.filter(room => room.isMemberOfRoom || room.isLocked), [flightrooms]);
  const allFlightrooms = useMemo(() => flightrooms, [flightrooms]);

  const getFlightroomsByIds = useCallback((ids: string[]) => flightrooms.filter(room => ids?.includes(room.id)), [flightrooms]);

  const updateFlightrooms = useCallback((newRooms: TFlightroom[]) => {
    setFlightrooms(prev => uniqBy([...newRooms, ...prev], 'id'));
  }, []);

  const addFlightroom = useCallback((flightroom: TFlightRoomDataWithoutUserInfo) => {
    const { flightRoomId, ...newRoom } = flightroom;
    setFlightrooms(rooms => [...rooms, { ...newRoom, id: flightRoomId }]);
  }, []);

  const deleteFlightroom = useCallback((id: string) => {
    setFlightrooms(rooms => rooms.filter(room => room.id !== id));
  }, []);

  const deleteRoom = () => {
    setSelectedRoom(undefined);
  };

  const getFlightroomById = useCallback((id: string) => flightrooms.find(room => room.id === id), [flightrooms]);

  const updateFlightroom = useCallback((flightroomUpdate: TFlightRoomDataWithoutUserInfo | TFlightroomPersonalInfo) => {
    setFlightrooms(rooms =>
      rooms.map(room => {
        const { flightRoomId, ...updatedScheduleProps } = flightroomUpdate;
        return room.id === flightRoomId ? { ...room, ...updatedScheduleProps } : room;
      }),
    );
  }, []);

  const resetFlightroomsContext = useCallback(() => {
    setFlightrooms([]);
  }, []);

  return (
    <FlightroomsContext.Provider
      value={{
        addFlightroom,
        deleteRoom,
        deleteFlightroom,
        getFlightroomById,
        getFlightroomsByIds,
        allFlightrooms,
        myFlightrooms,
        openFlightroom: setSelectedFlightroomId,
        resetFlightroomsContext,
        selectedFlightroomId,
        setFlightrooms: updateFlightrooms,
        updateFlightroom,
        openRoom: setSelectedRoom,
        selectedRoom: selectedRoom,
      }}
    >
      {children}
    </FlightroomsContext.Provider>
  );
};
