import { useEffect } from 'react';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { TNotification } from 'types';

import { useNotificationsContext } from '../../_context';
import { HttpClient, TApiError, THttpMetadataQuery } from '../../_http';

function getNotifications(payload?: THttpMetadataQuery): Promise<TNotification[]> {
  return HttpClient.get<TNotification[]>('/api/v1/notifications', payload);
}

export const useGetNotifications = (payload?: THttpMetadataQuery, options?: UseQueryOptions<TNotification[]>) => {
  const { setNotifications } = useNotificationsContext();
  const query = useQuery<TNotification[], TApiError>(['getNotifications', payload], () => getNotifications(payload), options);
  const { data, isSuccess } = query;

  useEffect(() => {
    if (isSuccess) setNotifications(data);
  }, [isSuccess, data]);

  return query;
};
