import { HttpClient, TApiError } from '@aviobook/_http';
import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from 'types';
import { TChatbot } from 'types/src/chatbot';

function chatbots(): Promise<TChatbot[]> {
  return HttpClient.get('/api/v1/admin/chatbots');
}

export function useChatbots() {
  return useQuery<TChatbot[], TApiError>([QUERY_KEYS.CHATBOTS], chatbots);
}
