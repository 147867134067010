import { HttpClient, TApiError } from '@aviobook/_http';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { QUERY_KEYS } from 'types';

function getQuickReplies(): Promise<string[]> {
  return HttpClient.get<{ quickReplies: string[] }>(`/api/v1/messages/quick-replies`).then(data => data.quickReplies);
}

export const useGetQuickReplies = (options?: UseQueryOptions<string[]>) =>
  useQuery<string[], TApiError>([QUERY_KEYS.QuickReplies], () => getQuickReplies(), options);
