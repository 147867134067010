import { HttpClient, TApiError } from '@aviobook/_http';
import { queryClient } from '@aviobook/index';
import { InfiniteData, useMutation } from '@tanstack/react-query';
import { QUERY_KEYS, TFlightroomData } from 'types';

function leaveFlightroom(roomId: string): Promise<boolean> {
  return HttpClient.post(`/api/v1/rooms/leave/${roomId}`);
}

export function useLeaveFlightroom() {
  return useMutation<boolean, TApiError, string>(['leaveFlightroom'], leaveFlightroom, {
    onMutate: variables => {
      queryClient.setQueriesData(['getAllFlightrooms'], (cacheFlightRooms: InfiniteData<TFlightroomData>) => {
        const newPages = cacheFlightRooms.pages.map(page => ({
          ...page,
          data: page?.data.filter(room => room.id !== variables),
        }));
        return {
          ...cacheFlightRooms,
          pages: newPages,
        };
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries([QUERY_KEYS.GET_ADDABLE_FLIGHTROOMS]);
      await queryClient.invalidateQueries([QUERY_KEYS.GET_FLIGHTROOMS]);
    },
  });
}
