import { DefaultOptions as ReactQueryOptions, QueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from 'types';

import { HttpStatus, TApiError } from './_http';

export type TEnvironment = 'local' | 'development' | 'test' | 'staging' | 'production';

const nodeEnv = process.env.NODE_ENV;

function assertNodeEnv(env: string | undefined): asserts env {
  if (!env) {
    throw Error('NODE ENV must be specified');
  }
}

assertNodeEnv(nodeEnv);

export class Config {
  static get api(): { apiKey: string; host: string } {
    return { apiKey: process.env.REACT_APP_API_KEY as string, host: process.env.REACT_APP_API_HOST as string };
  }

  static get brandName(): string {
    return process.env.REACT_APP_BRAND_NAME as string;
  }

  static get environment(): TEnvironment {
    return nodeEnv as TEnvironment;
  }

  static get sentryDsn(): string {
    return process.env.REACT_APP_SENTRY_DSN as string;
  }

  static get reactQuery(): ReactQueryOptions {
    const handleUnauthorizedCalls = (error: TApiError) => {
      if (['auth/verify', 'login'].some(url => window.location.href.includes(url))) {
        return;
      }

      if (error.statusCode !== HttpStatus.Unauthorized) {
        return;
      }

      if (window.location.href.includes('admin')) {
        new QueryClient().invalidateQueries([QUERY_KEYS.AUTHENTICATE_CMS]);
        window.location.href = 'admin/login';
        return;
      }
      // do authenticate call again, redirect to login onError
      new QueryClient().invalidateQueries([QUERY_KEYS.AUTHENTICATE]);
      window.location.href = '../auth/login';
    };
    return {
      mutations: {
        // onError: handleUnauthorizedCalls,
        retry: false,
      },
      queries: {
        onError: handleUnauthorizedCalls,
        refetchOnWindowFocus: true,
        retry: false,
        staleTime: 1000 * 60, // 1 minute
      },
    };
  }
}
