import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Spinner, Text } from '@aviobook/_shared';
import { PageContentBlock, SaveButton } from '@aviobook/cms/_shared';

import { useConfiguration } from './_hooks/useConfiguration';

import './configuration.scss';

export const Configuration = () => {
  const { t } = useTranslation();
  const { data, isLoading, update } = useConfiguration();
  const [checked, setChecked] = useState<boolean>(undefined);

  if (isLoading) {
    return <Spinner>{t('SHARED.LOADING_APPLICATION')}</Spinner>;
  }

  const isDisabled = checked === undefined || checked === data?.showUserNames;

  return (
    <>
      <div className="role-form-top-container">
        <SaveButton isDisabled={isDisabled} onClick={() => update(checked)} />
      </div>
      <PageContentBlock title={t('CHATBOT.PRIVACY.CONFIGURATION.TITLE')}>
        <Text className="text-wrapper" color="aviobookLightPrimaryAlpha" size="MD" uppercase weight="bold">
          {t('CHATBOT.PRIVACY.CONFIGURATION.TEXT')}
        </Text>
        <Checkbox
          checked={checked ?? data?.showUserNames}
          className="checkbow-wrapper"
          name="configuration"
          onChange={setChecked}
        />
      </PageContentBlock>
    </>
  );
};
