import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon, InputField, Text } from "@aviobook/_shared";
import logo from 'assets/images/aviobook_logo.png';
import paper_airplane from 'assets/images/paper_airplane.png';

import { useForm } from '../../_hooks';
import { TFormValidationErrors } from '../../_hooks/useForm';
import { formValidator } from '../../_utils/formValidation';
import { TLoginForm } from '../_models';
import { useLogin } from '../_queries';

import './login.scss';

const initialForm: TLoginForm = {
  email: '',
  organization: '',
};

function validateForm(values: TLoginForm): TFormValidationErrors<TLoginForm> {
  return {
    email: formValidator.email(values.email),
    organization: formValidator.organization(values.organization),
  };
}

const Login: FC = () => {
  const { t } = useTranslation();

  const { mutate: login, error, isLoading, isSuccess, isError } = useLogin();

  const form = useForm<TLoginForm>({
    error,
    initialForm,
    submitForm: values => login(values),
    validateForm,
  });

  if (isSuccess) return <LoginConfirmation />;

  const renderText = () => {
    const showBothErrors = !form?.validationErrors?.email?.isValid && !form?.validationErrors?.organization?.isValid;

    // Org field check
    if (!form?.validationErrors?.organization?.isValid && !showBothErrors) return form?.validationErrors?.organization?.message;

    // Email field check
    if (!form?.validationErrors?.email?.isValid && !showBothErrors) return form?.validationErrors?.email?.message;

    // Check's both fields
    if ((form.hasValidationErrors && showBothErrors) || isError) return t('AUTH.ERRORS.UNAUTHORIZED');

    if (error) return t('AUTH.ERRORS.UNAUTHORIZED');

    return null;
  };

  return (
    <main className="container">
      <div className="content-container">
        <img alt="logo" src={logo} width={160} />
        <span className="login-forgot-password-text">
          <Text color="aviobookDarkPrimaryCharlie" size="SM">
            {t('AUTH.LOGIN.ACTIVATION_REQUEST_1')}
          </Text>
          <Text color="aviobookDarkPrimaryCharlie" size="SM">
            {t('AUTH.LOGIN.ACTIVATION_REQUEST_2')}
          </Text>
        </span>
        <form onSubmit={form.submit}>
          <fieldset className="form-fieldset">
            <InputField
              className="input-field"
              iconName="briefcaseOutline"
              name="organization"
              onChange={form.setAttribute}
              placeholder={t('AUTH.LOGIN.ORGANIZATION')}
              required
              validation={form.validationErrors.organization}
              value={form.values.organization}
            />
            <InputField
              className="input-field"
              iconName="profileOutline"
              name="email"
              onChange={form.setAttribute}
              placeholder={t('AUTH.LOGIN.EMAIL')}
              required
              validation={form.validationErrors.email}
              value={form.values.email}
            />
          </fieldset>
          {(form?.hasValidationErrors || error) && (
            <span className="login-input-error-container">
              <Icon name="exclamationHexagonFilled" />
              <Text as="span" className="login-input-error-message" color="coreWarning" size="XS" weight="semiBold">
                {renderText()}
              </Text>
            </span>
          )}
          <Button className="button" loading={isLoading} type="submit">
            {t('AUTH.LOGIN.SEND_LINK')}
          </Button>
        </form>
      </div>
    </main>
  );
};

const LoginConfirmation = () => {
  const { t } = useTranslation();

  return (
    <main className="web-container">
      <div className="web-content-container confirmation">
        <img alt="logo" height={240} src={paper_airplane} />
        <Text as="h1" className="confirmation-title" color="aviobookLightPrimaryAlpha" size="XL" weight="bold">
          {t('AUTH.LOGIN.AUTHORIZE.TITLE')}
        </Text>
        <Text as="p" color="aviobookLightPrimaryAlpha" size="SM">
          {t('AUTH.LOGIN.AUTHORIZE.MESSAGE')}
        </Text>
      </div>
    </main>
  );
};

export default Login;
