import { io } from 'socket.io-client';

import { Config } from '../config';

export const chatSocket = io(Config.api.host, {
  autoConnect: false,
  path: '/api/v1/chat',
  withCredentials: true,
});

export const flightRoomSocket = io(Config.api.host, {
  autoConnect: false,
  path: '/api/v1/flight-room-socket',
  withCredentials: true,
});

export const personalInfoSocket = io(Config.api.host, {
  autoConnect: false,
  path: '/api/v1/personal-info-socket',
  withCredentials: true,
});

export const connectAllSockets = () => {
  chatSocket.connect();
  flightRoomSocket.connect();
  personalInfoSocket.connect();
};

export const disconnectAllSockets = () => {
  chatSocket.disconnect();
  flightRoomSocket.disconnect();
  personalInfoSocket.disconnect();
};
