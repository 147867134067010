import { FC } from 'react';
import { useAuthenticateCMS } from '@aviobook/cms/auth/_queries';
import { TFontSize } from 'styles';
import { TRolePermission } from 'types';

import { Tab } from './Tab';

import './tabBar.scss';

type TProps = {
  currentPage: string;
  navBarClassName?: 'top-nav-bar' | 'nav-bar';
  setTab: (tab: string) => void;
  tabRoutes: { label: string; name: string; permissions: TRolePermission[] }[];
  textSize?: TFontSize;
};

export const TabBar: FC<TProps> = ({ tabRoutes, currentPage, setTab, textSize, navBarClassName }) => {
  const { hasPermission } = useAuthenticateCMS();
  if (tabRoutes.length === 0) {
    return null;
  }

  return (
    <nav className={navBarClassName}>
      <ul className="nav-bar-sub-ul">
        {tabRoutes.map((t, index) => {
          const showTab = t.permissions.some(p => hasPermission(p));

          if (showTab) {
            const isActiveTab = t.name === currentPage;
            return <Tab isActiveTab={isActiveTab} key={index} name={t.label} setTab={() => setTab(t.name)} textSize={textSize} />;
          }
          return null;
        })}
      </ul>
    </nav>
  );
};
