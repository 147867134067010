import DATE_TIME_FORMATS from './formats.constants';
import { DateTime, DateTimeParts, DateTimePartsEnum } from './avio-date-time.model';
import { diff, isAfter, isBefore, isBetween, isSame, isSameOrAfter, isSameOrBefore } from './compare.util';
import { add, endOf, iso8601ToMilliseconds, startOf, subtract } from './date-math.util';
import { format, formatUTC, toISOString, UTC, valueOf } from './format.util';

const isIsoTimeStringRegex = new RegExp(/Z$/, 'i');

class AvioDateTimeUtil {
  value: Date;

  constructor(value = new Date()) {
    this.value = value;
  }

  private isValid(): boolean {
    return this.value.getTime() === this.value.getTime();
  }

  parse(date: DateTime) {
    this.value = this.parseDate(date);
    return this;
  }

  parseDate(date: DateTime): Date {
    if (!date) {
      return new Date();
    }

    if (date instanceof Date) {
      return date;
    }

    if (!isIsoTimeStringRegex.test(date)) {
      const d = (date.match(DATE_TIME_FORMATS.PARSE_REGEX) || []).map(Number);

      if (d.length !== 0) {
        const m = d[2] - 1 || 0
        const ms = (d[7] || 0)

        return new Date(
          d[1],
          m,
          d[3] || 1,
          d[4] || 0,
          d[5] || 0,
          d[6] || 0,
          ms,
        );
      }
    }

    return new Date(date);
  }

  add(args: DateTimeParts) {
    this.value = add(this.value, args);
    return this;
  }

  subtract(args: DateTimeParts){
    this.value = subtract(this.value, args);
    return this;
  }

  startOf() {
    this.value = startOf(this.value);
    return this;
  }

  endOf(){
    this.value = endOf(this.value);
    return this;
  }

  formatUTC(arg: string) {
    if (!this.isValid()) return null;

    return formatUTC(this.value, arg);
  }

  format(arg: string) {
    if (!this.isValid()) return null;

    return format(this.value, arg);
  }

  valueOf() {
    return valueOf(this.value);
  }

  toISOString() {
    return toISOString(this.value);
  }

  UTC(): Date {
    return UTC(this.value);
  }

  isBefore(dateToCompare: DateTime = new Date()) {
    return isBefore(this.value, this.parseDate(dateToCompare));
  }

  isSameOrBefore(dateToCompare: DateTime = new Date()) {
    return isSameOrBefore(this.value, this.parseDate(dateToCompare));
  }

  isAfter(dateToCompare: DateTime = new Date()) {
    return isAfter(this.value, this.parseDate(dateToCompare));
  }

  isSameOrAfter(dateToCompare: DateTime = new Date()) {
    return isSameOrAfter(this.value, this.parseDate(dateToCompare));
  }

  isSame(dateToCompare: DateTime = new Date()) {
    return isSame(this.value, this.parseDate(dateToCompare));
  }

  isBetween(dateFrom: DateTime, dateTo: DateTime) {
    return isBetween(this.value, this.parseDate(dateFrom), this.parseDate(dateTo));
  }

  diff(dateToCompare: Date, unit?: DateTimePartsEnum): number
  diff(dateToCompare: Date, unit?: DateTimePartsEnum[]): Record<DateTimePartsEnum, number>
  diff(dateToCompare: Date, unit?: DateTimePartsEnum | DateTimePartsEnum[]) {
    if (unit){
      if (Array.isArray(unit)){
        return unit.reduce((prev, current) => {
          prev[current] = diff(this.value, dateToCompare, current);

          return prev;
        }, <Record<DateTimePartsEnum, number>>{});
        return 
      } else {
        return diff(this.value, dateToCompare, unit);
      }
    }
  }
}

export const AvioDateTime = (value = new Date()) => new AvioDateTimeUtil(value);
export const parseDate = (value: DateTime | null = new Date()) => new AvioDateTimeUtil().parse(value ?? new Date());
export const addToDateTime = (value: Date, args: DateTimeParts) => parseDate(value).add(args);
export const subtractFromDateTime = (value: Date, args: DateTimeParts) => parseDate(value).subtract(args);
export const startOfDateTime = (value: Date) => parseDate(value).startOf();
export const endOfDateTime = (value: Date) => parseDate(value).endOf();
export const formatUTCDateTime = (value: Date, arg: string) => parseDate(value).formatUTC(arg);
export const formatDateTime = (value: Date, arg: string) => parseDate(value).format(arg);
export const valueOfDateTime = (value: Date) => parseDate(value).valueOf();
export const UTCDateTime = (value: Date) => parseDate(value).UTC();
export const toISOStringDateTime = (value: Date) => parseDate(value).toISOString();
export const isBeforeDateTime = (value: Date, dateToCompare: Date = new Date()) => parseDate(value).isBefore(dateToCompare);
export const isSameOrBeforeDateTime = (value: Date, dateToCompare: Date = new Date()) => parseDate(value).isSameOrBefore(dateToCompare);
export const isAfterDateTime = (value: DateTime, dateToCompare: DateTime = new Date()) => parseDate(value).isAfter(dateToCompare);
export const isSameOrAfterDateTime = (value: DateTime, dateToCompare: DateTime = new Date()) => parseDate(value).isSameOrAfter(dateToCompare);
export const isSameDateTime = (value: DateTime, dateToCompare: DateTime = new Date()) => parseDate(value).isSame(dateToCompare);
export const isBetweenDateTime = (value: DateTime, dateFrom: DateTime, dateTo: Date| string) => parseDate(value).isBetween(dateFrom, dateTo);
export const diffDateTime = (value: Date, dateToCompare: Date, unit: DateTimePartsEnum) => parseDate(value).diff(dateToCompare, unit);
export const iso8601ToMillisecondsDateTime = (value: string) => iso8601ToMilliseconds(value);
export const formatDateToDateTime = (date: DateTime) => parseDate(date).formatUTC(DATE_TIME_FORMATS.DATE_TIME);
export const formatDateToDate = (date: DateTime) => parseDate(date).formatUTC(DATE_TIME_FORMATS.DATE);
export const formatDateToTime = (date: DateTime) => parseDate(date).formatUTC(DATE_TIME_FORMATS.TIME);
export const formatDateToDateDay = (date: DateTime) => parseDate(date).formatUTC(DATE_TIME_FORMATS.DATE_DAY);
