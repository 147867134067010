import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../index';

type TProps = {
  isDisabled?: boolean;
  label?: string;
  onClick?: () => void;
};

export const SaveButton: FC<TProps> = ({ label, onClick, isDisabled }) => {
  const { t } = useTranslation();
  return (
    <Button disabled={isDisabled} onClick={onClick} theme="primary" type="submit">
      {label ? label : t('SHARED.BUTTONS.SAVE')}
    </Button>
  );
};
