export type THttpMetadataPagingResponse = {
  count: number;
  skip: number;
  totalCount: number;
};

export enum THttpSortDirection {
  Ascending = 'ASC',
  Descending = 'DESC',
}

export type THttpPagedResponse<T, M = unknown> = {
  data: T[];
  meta: THttpMetadataPagingResponse & M;
};

export enum TRoomArrivalStatus {
  All = 'ALL',
  Completed = 'COMPLETED',
  Ongoing = 'ONGOING',
}

export type THttpMetadataQuery = {
  roomArrivalStatus?: TRoomArrivalStatus;
  search?: string;
  skip?: number;
  sortBy?: string;
  sortDirection?: THttpSortDirection;
  take?: number;
};

export type TFillMetadataQueryFunction = (partialQuery: THttpMetadataQuery) => void;
