import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../../_shared';
import { useRequestAccess } from '../../flightrooms/_queries';
import Modal from '../Modal';
import { ModalOpener } from '../ModalOpener';

type TProps = {
  canJoinFlightRooms: boolean;
  content: string;
  isVisible?: boolean;
  roomId: string;
  searchQuery: string;
  title: string;
};

export const RequestFlightroomAccessModal: FC<TProps> = ({ content, canJoinFlightRooms, roomId, searchQuery, title }) => {
  const { mutateAsync: requestAccess, isLoading } = useRequestAccess(searchQuery);
  const { t } = useTranslation();

  const onRequestAccess = async () => {
    await requestAccess({ canJoinFlightRooms, roomId });
    // TODO: only close onSuccess and not on error
    ModalOpener.instance.close();
  };

  const onCancel = () => {
    if (!isLoading) {
      ModalOpener.instance.close();
    }
  };

  return (
    <Modal onCancel={onCancel}>
      <Modal.Header onCancel={onCancel}>{title}</Modal.Header>
      <Modal.Content>{content}</Modal.Content>
      <Modal.Buttons>
        <Button isOutline onClick={onCancel} theme="dark">
          {t('SHARED.BUTTONS.CANCEL')}
        </Button>
        <Button loading={isLoading} onClick={onRequestAccess} theme="primary">
          {t('FLIGHTROOMS.REQUEST_ACCESS.REQUEST')}
        </Button>
      </Modal.Buttons>
    </Modal>
  );
};
