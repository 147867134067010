/* eslint sort-keys-fix/sort-keys-fix: 0 */
// This is divided by 10 for conversion to rem
export const SPACING_WEB = {
  xxs: 0.1,
  xs: 0.3,
  s: 0.5,
  m: 1,
  l: 1.5,
  xl: 2.5,
  xxl: 3,
};

export const SPACING_MOBILE = {
  xxs: 1,
  xs: 3,
  s: 5,
  m: 10,
  l: 15,
  xl: 25,
  xxl: 30,
};

export const FONT_FAMILY = {
  OpenSansBold: 'OpenSans-Bold',
  OpenSansBoldItalic: 'OpenSans-BoldItalic',
  OpenSansExtraBoldItalic: 'OpenSans-ExtraBoldItalic',
  OpenSansItalic: 'OpenSans-Italic',
  OpenSansLight: 'OpenSans-Light',
  OpenSansLightItalic: 'OpenSans-LightItalic',
  OpenSansMedium: 'OpenSans-Medium',
  OpenSansMediumItalic: 'OpenSans-MediumItalic',
  OpenSansRegular: 'OpenSans-Regular',
  OpenSansSemiBold: 'OpenSans-SemiBold',
  OpenSansSemiBoldItalic: 'OpenSans-SemiBoldItalic',
};
