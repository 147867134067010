import { useTranslation } from 'react-i18next';
import { HttpClient } from '@aviobook/_http';
import { queryClient } from '@aviobook/index';
import { toastError, toastSuccess } from '@aviobook/Toastify';
import { useMutation } from '@tanstack/react-query';
import { QUERY_KEYS } from 'types';

function deleteQuickReply(id: string) {
  return HttpClient.delete(`/api/v1/admin/quick-replies/${id}`);
}

export function useDeleteQuickReplies() {
  const { t } = useTranslation();
  const mutation = useMutation(['deleteChatbot'], deleteQuickReply);

  const deleteSelectedQuickReplies = async (ids: string[]) => {
    const promises = ids.map(id => mutation.mutateAsync(id));
    try {
      return Promise.all(promises);
    } catch (e) {
      toastError(t('TOAST_ERROR'));
    } finally {
      queryClient.invalidateQueries([QUERY_KEYS.CHATBOTS]);
      toastSuccess(t('TOAST_SUCCESS'));
    }
  };

  return { ...mutation, deleteSelectedQuickReplies };
}
