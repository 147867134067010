import { ROLE } from './constants';

export type TMessage = {
  attachment?: string;
  createdDate: Date;
  id: string;
  isSystemMessage: boolean;
  message: string;
  messageId: string;
  senderId?: string;
  senderInfo?: {
    jobTitle?: ROLE;
    username: string;
    userId: string;
  };
  systemMessageLevel?: SYSTEM_MESSAGE_LEVEL;
};

// TODO: rename to SYSTEM_MESSAGE_LEVEL when we implement in mobile
export enum SYSTEM_MESSAGE_LEVEL {
  ATTENTION = 'ATTENTION',
  CAUTION = 'CAUTION',
  IMPORTED = 'IMPORTED',
  OK = 'OK',
  UNCLEAR = 'UNCLEAR',
  UNKNOWN = 'UNKNOWN',
  WARNING = 'WARNING',
}

export type TErrorMessage = {
  error: Record<string, string>;
  errorCode: string;
  messageId: string;
};

export type TMessagePayload = Partial<TMessage & { roomId: string }>;
