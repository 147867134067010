import { useTranslation } from 'react-i18next';
import { PageContent, TTab } from '@aviobook/cms/_shared/pageContent/PageContent';

import { General, Roles } from './_screens';

export const Connect = () => {
  const { t } = useTranslation();
  const tabs: TTab[] = [
    {
      label: t('TAB.GENERAL.TITLE'),
      name: 'General',
      permissions: [
        'VIEW_CHATBOTS',
        'ADD_CHATBOTS',
        'ADD_API_KEYS',
        'DELETE_CHATBOTS',
        'VIEW_API_KEYS',
        'UPDATE_API_KEYS',
        'UPDATE_CHATBOTS',
        'UPDATE_ORGANIZATION_SETTINGS',
        'VIEW_QUICK_REPLIES',
        'ADD_QUICK_REPLIES',
        'UPDATE_QUICK_REPLIES',
      ],
      screen: () => <General />,
    },
    {
      label: t('TAB.ROLE'),
      name: 'Roles',
      permissions: ['VIEW_ROLES', 'UPDATE_ROLES'],
      screen: () => <Roles />,
    },
  ];
  return <PageContent navBarClassName="top-nav-bar" tabs={tabs} textSize="XL" />;
};
