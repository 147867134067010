import React, { FC, useState } from 'react';

import { TTabBar } from './TabBar.types';

import './tabBar.scss';

type TProps = {
  initialTab?: number;
  tabs: TTabBar[];
};

export const TabBar: FC<TProps> = ({ tabs, initialTab = 0 }) => {
  const [activeTab, setActiveTab] = useState(initialTab);

  const handleTabClick = (index: number) => {
    setActiveTab(index);
    const { onClick } = tabs[index];
    if (onClick) {
      onClick();
    }
  };

  return (
    <div className="tab-bar">
      <ul className="tab-list">
        {tabs.map((tab, index) => (
          <li className={`tab-item ${activeTab === index ? 'active' : ''}`} key={index} onClick={() => handleTabClick(index)}>
            {tab.text}
          </li>
        ))}
      </ul>
    </div>
  );
};
