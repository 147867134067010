import { HttpClient, TApiError, THttpMetadataQuery, THttpPagedResponse } from '@aviobook/_http';
import { getQueryParams } from '@aviobook/_utils/queryHelpers';
import { useInfiniteQuery, UseInfiniteQueryOptions } from '@tanstack/react-query';
import { TMessage } from 'types';

export type TMessagesResponse = THttpPagedResponse<TMessage, { lastInteractionDate: Date }>;

async function getMessages(roomId: string, query: THttpMetadataQuery): Promise<TMessagesResponse> {
  return HttpClient.get<TMessagesResponse>(`/api/v1/messages/${roomId}${getQueryParams(query)}`);
}

export function useMessages(
  roomId: string,
  query?: THttpMetadataQuery,
  options?: UseInfiniteQueryOptions<TMessagesResponse, TApiError>,
) {
  return useInfiniteQuery<TMessagesResponse, TApiError>(
    ['messages', roomId, query],
    context => getMessages(roomId, { ...query, skip: context.pageParam }),
    {
      getNextPageParam: lastPage => {
        if (!lastPage || !lastPage.meta || lastPage.meta.count > lastPage.meta.totalCount) {
          return 0;
        }
        return lastPage.meta.skip + lastPage.meta.count;
      },
      ...options,
    },
  );
}
