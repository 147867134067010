import React, { FC } from 'react';
import classNames from 'classnames';

import { Text } from '../text/Text';

import './unreadDot.scss';

type TProps = {
  amount: number;
  size?: 'small' | 'medium' | 'large';
};

export const UnreadDot: FC<TProps> = ({ amount, size = 'small' }) => {
  return (
    <div className={classNames('unread-dot-container', size)}>
      <Text color="aviobookDarkPrimaryAlpha" size="SM" weight="bold">
        {amount}
      </Text>
    </div>
  );
};
