import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from "@aviobook/_shared";

import './unreadIndicator.scss';

type TProps = Record<string, never>;

export const UnreadIndicator: FC<TProps> = () => {
  const { t } = useTranslation();

  return (
    <div className="unread-indicator">
      <Text color="aviobookWebPrimaryZulu08" size="SM">
        {t('CHAT.UNREAD.MESSAGES')}
      </Text>
    </div>
  );
};
