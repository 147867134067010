import { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';
import { TSelectedFlightroomTab } from 'types';

type TAppContext = {
  imagePreview: string;
  isCurrentlySelectedTabEmpty: boolean;
  isEmptySearch: boolean;
  selectedFlightroomTab: TSelectedFlightroomTab;
  setImagePreview: (value: string | null) => void;
  setIsCurrentlySelectedTabEmpty: Dispatch<SetStateAction<boolean>>;
  setIsEmptySearch: Dispatch<SetStateAction<boolean>>;
  setSelectedFlightroomTab: Dispatch<SetStateAction<TSelectedFlightroomTab>>;
};

const AppContext = createContext<TAppContext>({
  imagePreview: null,
  isCurrentlySelectedTabEmpty: false,
  isEmptySearch: false,
  selectedFlightroomTab: 'active',
  setImagePreview: () => {},
  setIsCurrentlySelectedTabEmpty: () => {},
  setIsEmptySearch: () => {},
  setSelectedFlightroomTab: () => {},
});

export const useAppContext = () => useContext(AppContext);

export const AppContextProvider = ({ children }) => {
  const [selectedFlightroomTab, setSelectedFlightroomTab] = useState<TSelectedFlightroomTab>('active');
  const [isEmptySearch, setIsEmptySearch] = useState(false);
  const [isCurrentlySelectedTabEmpty, setIsCurrentlySelectedTabEmpty] = useState(true);
  const [imagePreview, setImagePreview] = useState<string>(null);

  return (
    <AppContext.Provider
      value={{
        imagePreview,
        isCurrentlySelectedTabEmpty,
        isEmptySearch,
        selectedFlightroomTab,
        setImagePreview,
        setIsCurrentlySelectedTabEmpty,
        setIsEmptySearch,
        setSelectedFlightroomTab,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
