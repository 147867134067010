import { FC } from 'react';
import { Icon, Text } from '@aviobook/_shared';

import { Button } from '../index';

import './confirmationModal.scss';

type TProps = {
  cancelButtonAction: () => void;
  cancelButtonLabel: string;
  confirmButtonAction: () => void;
  confirmButtonLabel: string;
  loading: boolean;
  title: string;
};

export const ConfirmationModal: FC<TProps> = ({
  cancelButtonLabel,
  confirmButtonLabel,
  confirmButtonAction,
  cancelButtonAction,
  loading,
  title,
}) => {
  return (
    <div className="modal-container">
      <Icon
        className="close-icon"
        colorName="aviobookDarkSecondaryHotel"
        name="closeCross"
        onClick={cancelButtonAction}
        size={2.5}
      />
      <div>
        <Text as="h5" color="aviobookCMSLightBlue" uppercase weight="bold">
          {title}
        </Text>
        <div className="modal-buttons">
          <Button isOutline onClick={cancelButtonAction} theme="neutral">
            {cancelButtonLabel}
          </Button>
          <Button loading={loading} onClick={confirmButtonAction} theme="negative">
            {confirmButtonLabel}
          </Button>
        </div>
      </div>
    </div>
  );
};
