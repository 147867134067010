import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import { BreadCrumbs } from '../breadcrumbs/BreadCrumbs';

import './authorizedLayout.scss';

export const AuthorizedLayout: FC = () => {
  return (
    <main className="authorized-layout">
      <BreadCrumbs />
      <Outlet />
    </main>
  );
};
