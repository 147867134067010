export const getQueryParams = (query?: Record<string, unknown>): string => {
  return query
    ? Object.keys(query).reduce(
        (queryParams, key) => (query[key] ? `${queryParams}${queryParams.length ? '&' : '?'}${key}=${query[key]}` : queryParams),
        '',
      )
    : '';
};

export const getUrlWithSearchParams = (url: string, query?: Record<string, unknown>): string => {
  const searchParams = new URLSearchParams();
  Object.entries(query).forEach(([key, value]) => {
    if (value !== null && value !== undefined) {
      searchParams.append(key, String(value));
    }
  });
  return `${url}?${searchParams.toString()}`;
};
