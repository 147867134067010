import { createContext, FC, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { TMessagePayload } from 'types';

type TStoredMessage = TMessagePayload & { isPending?: boolean };

type TOfflineMessagesContext = {
  deleteStoredMessage: (messageId: string) => void;
  getStoredMessagesByRoomId: (roomId: string) => TStoredMessage[];
  storeMessage: (message: TStoredMessage) => void;
};

const OfflineMessagesContext = createContext<TOfflineMessagesContext>({
  deleteStoredMessage: () => {},
  getStoredMessagesByRoomId: () => [],
  storeMessage: () => {},
});

export const useOfflineMessagesContext = () => useContext(OfflineMessagesContext);

type TProps = {
  children: ReactNode;
};

const getStoredMessages = () => JSON.parse(localStorage.getItem('storedMessages')) || [];

export const OfflineMessagesContextProvider: FC<TProps> = ({ children }) => {
  const [storedMessages, setStoredMessages] = useState<TStoredMessage[]>(getStoredMessages);

  useEffect(() => {
    localStorage.setItem('storedMessages', JSON.stringify(storedMessages));
  }, [storedMessages]);

  const getStoredMessagesByRoomId = useCallback(
    (roomId: string) => storedMessages.filter(message => message.roomId === roomId),
    [storedMessages],
  );

  const storeMessage = useCallback(
    (message: TStoredMessage) => setStoredMessages(prevState => [...prevState, { ...message, isPending: true }]),
    [setStoredMessages],
  );

  const deleteStoredMessage = useCallback(
    (messageId: string) => setStoredMessages(prevState => prevState.filter(message => message.messageId !== messageId)),
    [setStoredMessages],
  );

  return (
    <OfflineMessagesContext.Provider
      value={{
        deleteStoredMessage,
        getStoredMessagesByRoomId,
        storeMessage,
      }}
    >
      {children}
    </OfflineMessagesContext.Provider>
  );
};
