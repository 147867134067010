import { useTranslation } from 'react-i18next';
import { useAppContext } from "@aviobook/_context";
import { PaperAirplane } from 'assets';

import { Text } from '../../_shared';

import './empty.scss';

export const Empty = () => {
  const { t } = useTranslation();
  const { selectedFlightroomTab, isEmptySearch, isCurrentlySelectedTabEmpty } = useAppContext();

  const renderText = () => {
    if (isEmptySearch) {
      return;
    }
    if (!isCurrentlySelectedTabEmpty) {
      return <Text className="empty-body-text">{t('FLIGHTROOMS.SELECT_TO_SEND')}</Text>;
    } else {
      return (
        <>
          <Text as="h1" className="empty-title" color="aviobookLightPrimaryAlpha" size="LG" weight="bold">
            {t('FLIGHTROOMS.EMPTY_1')}
          </Text>
          <Text className="empty-body-text" color="aviobookDarkSecondaryHotel" size="SM">
            {t('FLIGHTROOMS.EMPTY_2')}
          </Text>
        </>
      );
    }
  };

  return (
    <div className={`empty-container ${selectedFlightroomTab}`}>
      <div className="empty-content-container">
        <figure className="empty-image">
          <img height={280} src={PaperAirplane} />
        </figure>
        {renderText()}
      </div>
    </div>
  );
};
