import en from 'translations/locales/en.json';

enum Language {
  Dutch = 'nl-BE',
  English = 'en',
}

export const options = {
  compatibilityJSON: 'v3' as const, // By default React Native projects does not support Intl
  fallbackLng: Language.English,
  interpolation: { escapeValue: false },
  resources: {
    en: { translation: en },
  },
};
