import { FC } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { AvioDateTime, DATE_TIME_FORMATS } from 'shared';

import { Icon, Text } from './_shared';

import 'react-toastify/dist/ReactToastify.css';
import './toastify.scss';

const CloseButton: FC<{
  closeToast?: () => void;
}> = ({ closeToast }) => <Icon name="SvgClose" onClick={closeToast || (() => {})} size={2.4} />;

const ToastMessageContainer: FC<{ message: string }> = ({ message }) => {
  return (
    <div className="toast-message-container">
      <Text as="span" size="XS" weight="semiBold">
        {message}
      </Text>
      <Text as="span" className="toast-message-time" color="coreWhite100" size="XXS" weight="semiBold">
        { AvioDateTime().format(DATE_TIME_FORMATS.TIME)}
      </Text>
    </div>
  );
};

export const toastSuccess = (message: string) => {
  toast.success(<ToastMessageContainer message={message} />, {
    bodyClassName: 'toast-body',
    className: 'toast',
    icon: <Icon name="check" size={2} />,
  });
};
export const toastError = (message: string) => {
  toast.error(<ToastMessageContainer message={message} />, {
    bodyClassName: 'toast-body',
    className: 'toast',
    icon: <Icon name="triangleCoreAttention" size={2} />,
    progress: null,
  });
};

export const Toastify: FC = () => (
  <ToastContainer closeButton={<CloseButton />} closeOnClick={false} hideProgressBar position="top-right" theme="colored" />
);
