import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Text } from '@aviobook/_shared';
import classnames from 'classnames';

import './breadcrumb.scss';

type TProps = {
  disabled: boolean;
  name: string;
};

export const BreadCrumb: FC<TProps> = ({ name, disabled }) => {
  return (
    <li className="nav-bar-li">
      <Link className={classnames(disabled ? 'disabled-link' : undefined)} to={name}>
        <Text as="span" color="aviobookLightPrimaryAlpha" size="XL" uppercase weight={disabled ? 'bold' : 'regular'}>
          {/* decodeURI is needed if you have a breadcrumb with spaces or special char */}
          {decodeURI(name)}
        </Text>
      </Link>
    </li>
  );
};
