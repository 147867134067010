import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useForm, { TFormValidationErrors } from '@aviobook/_hooks/useForm';
import { Spinner } from '@aviobook/_shared';
import { formValidator } from '@aviobook/_utils/formValidation';
import { RoleForm } from '@aviobook/cms/connect/_screens/roles/role/_tabs/roleDetails/roleform/RoleForm';
import { TRole } from 'types';

import { useUpdateRole } from '../../../_queries';

type TProps = {
  data: TRole;
  isLoadingRole: boolean;
};

function validateForm(values: TRole): TFormValidationErrors<TRole> {
  return {
    // TODO: replace this with the prop to set if a role is taggable.
    airlineRole: formValidator.required(values.airlineRole),
  };
}

export const RoleDetails: FC<TProps> = ({ isLoadingRole, data }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onSuccess = () => navigate('/admin/connect/roles');
  const { isLoading, mutate } = useUpdateRole(onSuccess);

  const form = useForm<TRole>({
    initialForm: data,
    submitForm: values => mutate(values),
    validateForm: values => validateForm(values),
  });

  if (isLoading || isLoadingRole) {
    return <Spinner>{t('SHARED.LOADING_APPLICATION')}</Spinner>;
  }

  return (
    <main className="update-role-container">
      <RoleForm form={form} />
    </main>
  );
};
