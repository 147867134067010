import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PageContent, TTab } from '@aviobook/cms/_shared/pageContent/PageContent';
import { Configuration } from '@aviobook/cms/connect/_screens/general/_tabs/configuration/Configuration';

import { ChatbotQuickReplies, ChatBots } from './_tabs';

export const General: FC = () => {
  const { t } = useTranslation();
  const TABS: TTab[] = [
    { label: t('TAB.GENERAL.CHATBOTS'), name: 'chatbot', permissions: ['VIEW_CHATBOTS'], screen: () => <ChatBots /> },
    {
      label: t('TAB.GENERAL.QUICK_REPLY'),
      name: 'quickReplies',
      permissions: ['VIEW_QUICK_REPLIES'],
      screen: () => <ChatbotQuickReplies />,
    },
    {
      label: t('TAB.GENERAL.CONFIGURATION'),
      name: 'configuration',
      permissions: ['UPDATE_ORGANIZATION_SETTINGS'],
      screen: () => <Configuration />,
    },
  ];

  return <PageContent tabs={TABS} textSize="MD" />;
};
