import { FC, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { TFontSize } from 'styles';
import { TRolePermission } from 'types/src/rolePermissions';

import { TabBar } from '../../_routing/tabbar/TabBar';
import { TabContent } from './TabContent';

export type TTab = {
  label: string;
  name: string;
  permissions: TRolePermission[];
  screen: () => JSX.Element;
};

type TProps = {
  navBarClassName?: 'top-nav-bar' | 'nav-bar';
  tabs: TTab[];
  textSize?: TFontSize;
};

export const PageContent: FC<TProps> = ({ tabs, textSize = 'MD', navBarClassName = 'nav-bar' }) => {
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab');
  const [selectedTab, setSelectedTab] = useState(tabs.find(t => t.name === tab) ? tab : tabs[0].name);

  return (
    <>
      <TabBar
        currentPage={selectedTab}
        navBarClassName={navBarClassName}
        setTab={setSelectedTab}
        tabRoutes={tabs}
        textSize={textSize}
      />
      <TabContent selectedTab={selectedTab} tabs={tabs} />
    </>
  );
};
