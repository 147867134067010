import { useTranslation } from 'react-i18next';
import { HttpClient } from '@aviobook/_http';
import { queryClient } from '@aviobook/index';
import { toastError, toastSuccess } from '@aviobook/Toastify';
import { useMutation } from '@tanstack/react-query';
import { TOrganizationSettings } from 'types';

type TPutOrganizationSettings = {
  shouldShowUsernames: boolean;
};

function mapOrganizationSettingsToPutOrganizationSettings(input: TOrganizationSettings): TPutOrganizationSettings {
  return {
    shouldShowUsernames: input.showUserNames,
  };
}

function organizationSettings(input: TOrganizationSettings): Promise<TOrganizationSettings> {
  return HttpClient.put(`/api/v1/admin/organization-settings`, mapOrganizationSettingsToPutOrganizationSettings(input));
}

export function useUpdateOrganizationSettings() {
  const { t } = useTranslation();

  return useMutation(['role'], organizationSettings, {
    onError: () => {
      toastError(t('TOAST_ERROR'));
    },
    onSuccess: () => {
      toastSuccess(t('TOAST_SUCCESS'));
      queryClient.invalidateQueries(['organization-settings']);
    },
  });
}
