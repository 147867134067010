import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { TNotification } from 'types';

type TNotificationsContext = {
  amtUnreadNotifications: number;
  notifications: TNotification[];
  onNotificationUpdate: (notifications: TNotification) => void;
  setNotifications: (notifications: TNotification[]) => void;
  setNotificationsRead: (roomId: string) => void;
};

const NotificationsContext = createContext<TNotificationsContext>({
  amtUnreadNotifications: 0,
  notifications: [],
  onNotificationUpdate: () => {},
  setNotifications: () => {},
  setNotificationsRead: () => {},
});

export const useNotificationsContext = () => useContext(NotificationsContext);

export const NotificationsContextProvider = ({ children }) => {
  const [notifications, setNotifications] = useState<TNotification[]>([]);

  const amtUnreadNotifications = useMemo(
    () => notifications.filter(notification => !notification.hasSeen).length,
    [notifications],
  );

  const onNotificationUpdate = useCallback((newNotification: TNotification) => {
    setNotifications(prev => [newNotification, ...prev]);
  }, []);

  const setNotificationsRead = useCallback((roomId: string) => {
    setNotifications(prev =>
      prev.map(notification => (notification.flightRoomId === roomId ? { ...notification, hasSeen: true } : notification)),
    );
  }, []);

  return (
    <NotificationsContext.Provider
      value={{
        amtUnreadNotifications,
        notifications,
        onNotificationUpdate,
        setNotifications,
        setNotificationsRead,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};
