import { DateTimeParts } from './avio-date-time.model';

export function add(value: Date, args: DateTimeParts) {
  const date = new Date(value);

  if (args.day) {
    date.setUTCDate(date.getUTCDate() + args.day);
  }

  if (args.month) {
    date.setUTCMonth(date.getUTCMonth() + args.month);
  }

  if (args.year) {
    date.setUTCFullYear(date.getUTCFullYear() + args.year);
  }

  if (args.hour) {
    date.setUTCHours(date.getUTCHours() + args.hour);
  }

  if (args.minute) {
    date.setUTCMinutes(date.getUTCMinutes() + args.minute);
  }

  if (args.second) {
    date.setUTCSeconds(date.getUTCSeconds() + args.second);
  }

  if (args.millisecond) {
    date.setUTCMilliseconds(date.getUTCMilliseconds() + args.millisecond);
  }

  return date;
}

export function subtract(value: Date, args: DateTimeParts) {
  const date = new Date(value);

  if (args.day) {
    date.setUTCDate(date.getUTCDate() - args.day);
  }

  if (args.month) {
    date.setUTCMonth(date.getUTCMonth() - args.month);
  }

  if (args.year) {
    date.setUTCFullYear(date.getUTCFullYear() - args.year);
  }

  if (args.hour) {
    date.setUTCHours(date.getUTCHours() - args.hour);
  }

  if (args.minute) {
    date.setUTCMinutes(date.getUTCMinutes() - args.minute);
  }

  if (args.second) {
    date.setUTCSeconds(date.getUTCSeconds() - args.second);
  }

  if (args.millisecond) {
    date.setUTCMilliseconds(date.getUTCMilliseconds() - args.millisecond);
  }

  return date;
}

export function startOf(value: Date) {
  const date = new Date(value);
  date.setUTCHours(0, 0, 0, 0);
  return date;
}

export function endOf(value: Date) {
  const date = new Date(value);
  date.setUTCHours(23, 59, 59, 999);
  return date;
}

export function iso8601ToMilliseconds(duration: string) {
  const pattern = /^P(?:(\d+)Y)?(?:(\d+)M)?(?:(\d+)W)?(?:(\d+)D)?(?:T(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?)?$/;
  const match = duration.match(pattern);

  if (!match) {
    throw new Error('Invalid ISO 8601 duration format');
  }

  const years = parseInt(match[1] || '0', 10);
  const months = parseInt(match[2] || '0', 10);
  const weeks = parseInt(match[3] || '0', 10);
  const days = parseInt(match[4] || '0', 10);
  const hours = parseInt(match[5] || '0', 10);
  const minutes = parseInt(match[6] || '0', 10);
  const seconds = parseInt(match[7] || '0', 10);

  const totalMilliseconds = (
    // eslint-disable-next-line max-len
    years * 31536000000 + months * 2592000000 + weeks * 604800000 + days * 86400000 + hours * 3600000 + minutes * 60000 + seconds * 1000
  );

  return totalMilliseconds;
}
