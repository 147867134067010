import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Checkbox, Text } from '@aviobook/_shared';
import { FlexComponent } from '@aviobook/cms/_shared';
import { useGetRole, useUpdateRole } from '@aviobook/cms/connect/_screens/roles/_queries';
import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { TRoleQuickReplies } from 'types';

export const useRoleQuickReplyContentTable = () => {
  const { t } = useTranslation();
  const { roleid } = useParams();

  const columnHelper = createColumnHelper<TRoleQuickReplies>();

  const { data: role, isLoading, refetch } = useGetRole(roleid);
  const { mutate: updateRole, isLoading: isMutateLoading } = useUpdateRole();

  const [itemsToDelete, setItemsToDelete] = useState<string[]>([]);
  const isRowSelected = (value: string) => {
    return itemsToDelete.find(item => item === value)?.length > 0;
  };
  const selectItem = (item: string, value) => {
    if (value) {
      setItemsToDelete([...itemsToDelete, item]);
    } else {
      setItemsToDelete(itemsToDelete.filter(i => i !== item));
    }
  };

  const isCheckedAll = () => {
    return role.quickReplies?.every(item => itemsToDelete.find(i => i === item.value));
  };
  const setSelectAll = (checked: boolean) => {
    let updatedItems = [];
    if (checked) {
      updatedItems = data.map(item => item.value);
    }
    setItemsToDelete(updatedItems);
  };

  const data = useMemo(() => {
    if (!role.quickReplies) return [];
    return role.quickReplies.sort((a, b) => a.value.localeCompare(b.value));
  }, [role.quickReplies]);

  const columns = [
    columnHelper.accessor('value', {
      cell: info => (
        <FlexComponent flexDirection="row">
          <Checkbox
            checked={isRowSelected(info.row.original.value)}
            name="quickReply"
            onChange={value => selectItem(info.row.original.value, value)}
          />
          <Text as="span" color="aviobookCMSLightBlue" size="MD" width="partial-width">
            {info.getValue()}
          </Text>
        </FlexComponent>
      ),
      header: () => (
        <FlexComponent backgroundColor="transparent" flexDirection="row">
          <Checkbox checked={isCheckedAll()} name="quickReplies" onChange={setSelectAll} />
          <Text as="span" color="aviobookLightPrimaryAlpha" size="XS" uppercase weight="bold" width="partial-width">
            {t(role.quickReplies.length > 1 ? 'SHARED.TEXT.QUICKREPLIES.MULTI' : 'SHARED.TEXT.QUICKREPLIES.SINGLE')}
          </Text>
        </FlexComponent>
      ),
      id: 'KEYWORDS',
      size: 10,
    }),
    columnHelper.accessor('description', {
      cell: info => (
        <Text as="span" color="aviobookCMSLightBlue" size="MD" width="partial-width">
          {info.getValue()}
        </Text>
      ),
      id: 'Description',
    }),
  ];

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
  });

  return {
    createQuickReply: async (quickReply: TRoleQuickReplies) => {
      const newQuickReplies = [...role.quickReplies, quickReply];
      await updateRole({ ...role, quickReplies: newQuickReplies });
      await refetch();
    },
    deleteQuickReplies: async () => {
      if (itemsToDelete.length > 0) {
        const newQuickReplies = role.quickReplies.filter(quickReply => !itemsToDelete.includes(quickReply.value));
        await updateRole({ ...role, quickReplies: newQuickReplies });
        setItemsToDelete([]);
        setSelectAll(false);
        await refetch();
      }
    },
    isLoading,
    isMutateLoading,
    itemsToDelete,
    table,
  };
};
