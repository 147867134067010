import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Text } from '@aviobook/_shared';
import { FlexComponent } from '@aviobook/cms/_shared';
import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { TQuickReply } from 'types/src/quickReply';

import { useDeleteQuickReplies } from '../_queries/useDeleteQuickReplies';
import { useQuickReplies } from '../_queries/useQuickReplies';
import { useUpdateQuickReplies } from '../_queries/useUpdateQuickReplies';

export const useChatbotQuickRepliesContentTable = () => {
  const { t } = useTranslation();
  const { mutate: createQuickReply, isLoading: isLoadingCreateQuickReply } = useUpdateQuickReplies();
  const { data: quickReplies, isLoading, refetch: refetchQuickReplies } = useQuickReplies();

  const { deleteSelectedQuickReplies, isLoading: isLoadingDeleteQuickReplies } = useDeleteQuickReplies();
  const columnHelper = createColumnHelper<TQuickReply>();

  const [itemsToDelete, setItemsToDelete] = useState<string[]>([]);
  const isRowSelected = (id: string) => {
    return itemsToDelete.find(item => item === id)?.length > 0;
  };
  const selectItem = (id: string, value) => {
    if (value) {
      setItemsToDelete([...itemsToDelete, id]);
    } else {
      setItemsToDelete(itemsToDelete.filter(item => item !== id));
    }
  };

  const isCheckedAll = () => {
    return quickReplies.every(item => itemsToDelete.find(i => i === item.id));
  };
  const setSelectAll = (checked: boolean) => {
    let updatedItems = [];
    if (checked) {
      updatedItems = data.map(item => item.id);
    }
    setItemsToDelete(updatedItems);
  };

  const data = useMemo(() => {
    if (!quickReplies) return [];
    return quickReplies.sort((a, b) => a.text.localeCompare(b.text));
  }, [quickReplies]);

  const columns = [
    columnHelper.accessor('text', {
      cell: info => (
        <FlexComponent flexDirection="row">
          <Checkbox
            checked={isRowSelected(info.row.original.id)}
            name="quickReply"
            onChange={value => selectItem(info.row.original.id, value)}
          />
          <Text as="span" color="aviobookCMSLightBlue" size="MD" width="partial-width">
            {info.getValue()}
          </Text>
        </FlexComponent>
      ),
      header: () => (
        <FlexComponent backgroundColor="transparent" flexDirection="row">
          <Checkbox checked={isCheckedAll()} name="quickReplies" onChange={setSelectAll} />
          <Text as="span" color="aviobookLightPrimaryAlpha" size="XS" uppercase weight="bold" width="partial-width">
            {t(quickReplies.length > 1 ? 'SHARED.TEXT.QUICKREPLIES.MULTI' : 'SHARED.TEXT.QUICKREPLIES.SINGLE')}
          </Text>
        </FlexComponent>
      ),
      id: 'KEYWORDS',
      size: 10,
    }),
    columnHelper.accessor('description', {
      cell: info => (
        <Text as="span" color="aviobookCMSLightBlue" size="MD" width="partial-width">
          {info.getValue()}
        </Text>
      ),
      id: 'Description',
    }),
  ];

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
  });

  return {
    createQuickReply,
    deleteQuickReplies: async () => {
      await deleteSelectedQuickReplies(itemsToDelete);
      await refetchQuickReplies();
    },
    isLoading,
    isLoadingCreateQuickReply,
    isLoadingDeleteQuickReplies,
    itemsToDelete,
    table,
  };
};
