import { FC } from 'react';
import Modal from 'react-modal';
import { Route, Routes } from 'react-router-dom';
import { AppWeb } from '@aviobook/AppWeb';
import { AppCms } from '@aviobook/cms/AppCms';
import { ModalWrapper } from '@aviobook/modal/ModalWrapper';
import { Toastify } from '@aviobook/Toastify';

Modal.setAppElement('#root');

export const App: FC = () => {
  return (
    <>
      <Routes>
        <Route element={<AppWeb />} path="*" />
        <Route element={<AppCms />} path="/admin/*" />
      </Routes>
      <ModalWrapper />
      <Toastify />
    </>
  );
};
