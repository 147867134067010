import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import { Spinner } from "@aviobook/_shared";
import { AddButton, TableLayout } from '@aviobook/cms/_shared';
import { ConfirmationModal } from '@aviobook/cms/_shared/confirmationModal/ConfirmationModal';
import { EmptyState } from '@aviobook/cms/_shared/emptyState/EmptyState';
import { customModalStyles } from '@aviobook/cms/_shared/modal/ModalUtils';
import { useAuthenticateCMS } from '@aviobook/cms/auth/_queries';

import { useChatbotContentTable } from './_hooks/useChatbotContentTable';

import './chatBots.scss';

export const ChatBots: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isModalOpen, setModalOpen] = useState(false);
  const { hasPermission } = useAuthenticateCMS();
  const { isLoading, table, deleteChatbots, isDeleteChatbotLoading, deleteButtonDisabled } = useChatbotContentTable();

  if (isLoading) return <Spinner>{t('SHARED.LOADING_APPLICATION')}</Spinner>;

  return (
    <div className="chatbots-container">
      <Modal isOpen={isModalOpen} onRequestClose={() => setModalOpen(false)} style={customModalStyles}>
        <ConfirmationModal
          cancelButtonAction={() => {
            setModalOpen(false);
          }}
          cancelButtonLabel={t('CHATBOTS_CONFIRMATIONMODAL_CANCEL_BUTTON_LABEL')}
          confirmButtonAction={async () => {
            await deleteChatbots();
            setModalOpen(false);
          }}
          confirmButtonLabel={t('CHATBOTS_CONFIRMATIONMODAL_CONFIRM_BUTTON_LABEL')}
          loading={isDeleteChatbotLoading}
          title={t('CHATBOTS_CONFIRMATIONMODAL_TITLE')}
        />
      </Modal>

      <TableLayout
        addButtonAction={() => navigate('/admin/connect/general/chatbots')}
        addButtonLabel={t('CHATBOTS_ADDBUTTON')}
        deleteButtonAction={() => setModalOpen(true)}
        deleteButtonDisabled={deleteButtonDisabled}
        emptyState={() => (
          <EmptyState
            button={() =>
              hasPermission('ADD_CHATBOTS') ? (
                <AddButton label={t('CHATBOTS_ADDBUTTON')} onClick={() => navigate('/admin/connect/general/chatbots')} />
              ) : null
            }
            description={t('CHATBOTS_EMPTYSTATE_DESCRIPTION')}
            title={t('CHATBOTS_EMPTYSTATE_TITLE')}
          />
        )}
        isAddButtonPresent={hasPermission('ADD_CHATBOTS')}
        isDeleteButtonPresent={hasPermission('DELETE_CHATBOTS')}
        table={table}
      />
    </div>
  );
};
