import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { TResponse } from '@aviobook/_hooks/useForm';
import { InputField } from '@aviobook/_shared';
import { isEmptyArray } from '@aviobook/_utils/arrayHelper';
import {
  AddButton,
  EmptyState,
  FlexComponent,
  PageContentBlock,
  SaveButton,
  TableLayout,
} from '@aviobook/cms/_shared';
import { DeleteButton } from '@aviobook/cms/_shared/deleteButton/DeleteButton';
import { customModalStyles } from '@aviobook/cms/_shared/modal/ModalUtils';
import { useAuthenticateCMS } from '@aviobook/cms/auth/_queries';
import { TChatbot } from 'types/src/chatbot';
import { Tkeyword } from 'types/src/keyword';

import { useKeywordTable } from '../_hooks/useKeywordTable';
import { AddKeywordModal } from './AddKeywordModal';

type TProps = {
  canSave: boolean;
  form: TResponse<TChatbot, TChatbot>;
};

export const ChatbotForm: FC<TProps> = ({ form, canSave }) => {
  const { t } = useTranslation();
  const [isOpenModal, setOpenModal] = useState(false);
  const { hasPermission } = useAuthenticateCMS();

  const { table, deleteKeyword } = useKeywordTable({ form });
  const addingKeyword = (keyword: Tkeyword) => {
    form.setAttribute([...form.values.keywords, keyword], 'keywords');
    setOpenModal(false);
  };

  return (
    <>
      <Modal
        contentLabel="Example Modal"
        isOpen={isOpenModal}
        onRequestClose={() => setOpenModal(false)}
        style={customModalStyles}
      >
        <AddKeywordModal closeModal={() => setOpenModal(false)} onsubmit={addingKeyword} />
      </Modal>
      <form onSubmit={form.submit}>
        {canSave ? (
          <div className="chatbot-container-top">
            <SaveButton isDisabled={!form.isDirty} />
          </div>
        ) : null}
        <PageContentBlock title={t('CHATBOTFORM_SETTINGS')}>
          <FlexComponent flexDirection="column" gap={1} width="full">
            <FlexComponent flexDirection="row" gap={1} width="full">
              <InputField
                label={t('CHATBOTFORM_URL')}
                name="url"
                onChange={form.setAttribute}
                required
                value={form.values?.url || ''}
              />
              {hasPermission('VIEW_API_KEYS') ? (
                <InputField
                  disabled={!hasPermission('UPDATE_API_KEYS')}
                  label={t('CHATBOTFORM_APIKEY')}
                  name="apiKey"
                  onChange={form.setAttribute}
                  required
                  value={form.values?.apiKey || ''}
                />
              ) : null}
            </FlexComponent>

            <FlexComponent flexDirection="row" gap={1} style={{ margin: '2rem' }} width="full">
              <InputField
                label={t('CHATBOTFORM_CHATBOTNAME')}
                name="name"
                onChange={form.setAttribute}
                required
                value={form.values?.name || ''}
              />
              <InputField
                label={t('CHATBOTFORM_DESCRIPTION')}
                name="description"
                onChange={form.setAttribute}
                value={form.values?.description || ''}
              />
            </FlexComponent>
          </FlexComponent>
        </PageContentBlock>
      </form>
      <PageContentBlock
        rightButton={
          !isEmptyArray(form.values?.keywords) && canSave ? (
            <div className="right-buttons">
              <div className="button-margin">
                <DeleteButton key="delete-button" onClick={deleteKeyword} />
              </div>
              <AddButton key="add-button" label={t('CHATBOTFORM_KEYWORDS_ADDBUTTON')} onClick={() => setOpenModal(true)} />
            </div>
          ) : null
        }
        title={t('CHATBOTFORM_KEYWORDS')}
      >
        <TableLayout
          emptyState={() => (
            <EmptyState
              button={() =>
                canSave ? <AddButton label={t('CHATBOTFORM_KEYWORDS_ADDBUTTON')} onClick={() => setOpenModal(true)} /> : null
              }
              description={t('CHATBOTFORM_KEYWORDS_EMPTY_DESCRIPTION')}
              title={t('CHATBOTFORM_KEYWORDS_EMPTY_TITLE')}
            />
          )}
          infoTableText={t('CHATBOTFORM_KEYWORDS_INFOTABLE')}
          table={table}
        />
      </PageContentBlock>
    </>
  );
};
