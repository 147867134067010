import { createContext, FC, ReactNode, useContext, useState } from 'react';

type TAuthenticationContext = {
  enableAuthentication?: boolean;
  enableAuthenticationCMS?: boolean;
  setEnableAuthentication?: (enable: boolean) => void;
  setEnableAuthenticationCMS?: (enable: boolean) => void;
};

const AuthenticationContext = createContext<TAuthenticationContext>({
  enableAuthentication: false,
  enableAuthenticationCMS: false,
  setEnableAuthentication: () => {},
  setEnableAuthenticationCMS: () => {},
});

export const useAuthenticationContext = () => useContext(AuthenticationContext);

type TProps = {
  children: ReactNode;
};

export const AuthenticationContextProvider: FC<TProps> = ({ children }) => {
  const [enableAuthentication, setEnableAuthentication] = useState(true);
  const [enableAuthenticationCMS, setEnableAuthenticationCMS] = useState(true);
  return (
    <AuthenticationContext.Provider
      value={{
        enableAuthentication,
        enableAuthenticationCMS,
        setEnableAuthentication,
        setEnableAuthenticationCMS,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};
