import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { parseCrumbs, useBreadcrumbs } from '@aviobook/_hooks/';

import { BreadCrumb } from './BreadCrumb';

export const BreadCrumbs: FC = () => {
  const location = useLocation();

  const { init, crumbs } = useBreadcrumbs(state => ({
    crumbs: parseCrumbs(state.crumbs),
    init: state.init,
  }));

  useEffect(() => {
    init(location.pathname.split('/').filter(x => x));
  }, [location.pathname]);

  return (
    <nav className="bread-crumb">
      <ul className="nav-bar-ul">
        {crumbs.map((name, index, { length }) => {
          const isLast = index === length - 1;
          return <BreadCrumb disabled={isLast} key={index} name={name} />;
        })}
      </ul>
    </nav>
  );
};
