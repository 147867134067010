import { FC } from 'react';

import { TTab } from './PageContent';

type TType = {
  selectedTab: string;
  tabs: TTab[];
};

export const TabContent: FC<TType> = ({ selectedTab, tabs }) => {
  const MyTab = tabs.find(t => t.name === selectedTab);
  return MyTab.screen();
};
