import { HttpClient } from '@aviobook/_http';
import { useQuery } from '@tanstack/react-query';
import { TOrganizationSettings } from 'types';

function getOrganizationSettings() {
  return HttpClient.get<TOrganizationSettings>('/api/v1/admin/organization-settings');
}

export function useGetOrganizationSettings() {
  return useQuery(['organization-settings'], getOrganizationSettings);
}
