import { HttpClient, TApiError } from '@aviobook//_http';
import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from 'types';
import { TQuickReply } from 'types/src/quickReply';

function quickReplies(): Promise<TQuickReply[]> {
  return HttpClient.get('/api/v1/admin/quick-replies');
}

export function useQuickReplies() {
  return useQuery<TQuickReply[], TApiError>([QUERY_KEYS.QuickReplies], quickReplies);
}
