import { createElement, FC } from 'react';
import clsx from 'clsx';
import { COLORS, TColorName, TFontSize, TFontWeight } from 'styles';

import './text.scss';

type TProps = {
  as?: 'p' | 'span' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
  children?: string | number | JSX.Element | JSX.Element[] | string[] | number[];
  className?: string;
  color?: TColorName;
  size?: TFontSize;
  style?: 'normal' | 'italic';
  textDecoration?: 'underline';
  uppercase?: boolean;
  weight?: TFontWeight;
  width?: string;
  wrap?: boolean;
};

export const Text: FC<TProps> = ({
                                   as = 'p',
                                   weight = 'regular',
                                   style = 'normal',
                                   className,
                                   size,
                                   color,
                                   uppercase,
                                   children,
                                   wrap,
                                   width = 'fullWidth',
                                   textDecoration,
                                 }) =>
  createElement(
    as,
    {
      className: clsx('text', size, weight, style, className, textDecoration, width, { uppercase, wrap }),
      style: { color: COLORS[color] },
    },
    children,
  );
