import { FC, Key } from 'react';
import Linkify from 'react-linkify';
import { MessageProps } from 'react-native-gifted-chat';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { formatDate, getIconForRole, TGiftedMessage } from 'shared';

import { useOfflineMessagesContext } from '../../../_context';
import { Avatar, Text } from '../../../_shared';
import { matchDecorator } from '../../_utils';
import { PendingMessageWrapper, UserInfo } from './_components';

import './messageBubble.scss';

type TProps = MessageProps<TGiftedMessage> & {
  onClickAttachment: (attachment: string) => void;
  onRetrySend: (message: string, attachment?: string) => void;
  userId: string;
};

export const MessageBubble: FC<TProps> = ({
  currentMessage,
  previousMessage,
  nextMessage,
  position,
  onRetrySend,
  onClickAttachment,
}) => {
  const { deleteStoredMessage } = useOfflineMessagesContext();
  const { user, text, attachment, pending } = currentMessage;
  const nextMessageBySameUser = nextMessage?.user?._id === currentMessage.user._id;
  const byMe = position === 'right' || pending;
  const timestamp = formatDate(new Date(currentMessage.createdAt), 'HH:mm');
  const hasAvatar = previousMessage.user?._id !== currentMessage.user?._id && !byMe;

  const linkifyComponentDecorator = (decoratedHref: string, decoratedText: string, key: Key) => {
    return (
      <Link className="message-bubble-hyperlink-text" key={key} rel="noopener noreferrer" target="_blank" to={decoratedHref}>
        {decoratedText}
      </Link>
    );
  };

  const onClickRetry = () => {
    deleteStoredMessage(currentMessage.messageId);
    onRetrySend(text, attachment);
  };

  return (
    <PendingMessageWrapper
      isPending={pending}
      nextMessageBySameUser={nextMessageBySameUser}
      onClickDelete={() => deleteStoredMessage(currentMessage.messageId)}
      onClickRetry={onClickRetry}
    >
      {hasAvatar ? <UserInfo user={user} /> : null}
      <div className="message-bubble-container">
        {hasAvatar && <Avatar icon={getIconForRole(user?.jobTitle)} />}
        <div
          className={classnames('message-bubble-content-container', {
            'by-me': byMe,
            'has-attachment': !!attachment,
            'has-avatar': hasAvatar,
          })}
        >
          {attachment ? (
            <button className="message-bubble-image-button-wrapper" onClick={() => onClickAttachment(attachment)}>
              <img crossOrigin="anonymous" src={attachment} />
            </button>
          ) : null}
          <Linkify componentDecorator={linkifyComponentDecorator} matchDecorator={matchDecorator}>
            <Text color={byMe ? 'aviobookDarkNeutralsLight' : 'aviobookLightPrimaryAlpha'} size="SM">
              {text}
            </Text>
          </Linkify>
          <Text className="text-date" color={byMe ? 'aviobookWebPrimaryZulu08' : 'aviobookWebPrimaryZulu05'} size="XXS">
            {timestamp}
          </Text>
        </div>
      </div>
    </PendingMessageWrapper>
  );
};

// TODO: uncomment this when taggable roles should be enabled again.

// import React, { FC, Key } from 'react';
// import Linkify from 'react-linkify';
// import { MessageProps } from 'react-native-gifted-chat';
// import { Link } from 'react-router-dom';
// import { useOfflineMessagesContext } from '@aviobook/_context';
// import { Avatar, Text } from '@aviobook/web/_shared';
// import { matchDecorator } from '@aviobook/web/chat/_utils';
// import classnames from 'classnames';
// import { formatDate, getIconForRole, TGiftedMessage } from 'shared';

// import { PendingMessageWrapper, UserInfo } from './_components';

// import './messageBubble.scss';

// type TProps = MessageProps<TGiftedMessage> & {
//   onClickAttachment: (attachment: string) => void;
//   onRetrySend: (message: string, attachment?: string) => void;
//   taggableRoles?: string[];
// };

// export const MessageBubble: FC<TProps> = ({
//   currentMessage,
//   previousMessage,
//   nextMessage,
//   position,
//   onRetrySend,
//   onClickAttachment,
//   taggableRoles,
// }) => {
//   const { deleteStoredMessage } = useOfflineMessagesContext();
//   const { user, text, attachment, pending } = currentMessage;
//   const nextMessageBySameUser = nextMessage?.user?._id === currentMessage.user?._id;
//   const byMe = position === 'right' || pending;
//   const timestamp = formatDate(new Date(currentMessage.createdAt), 'HH:mm');
//   const hasAvatar = previousMessage.user?._id !== currentMessage.user?._id && !byMe;

//   const linkifyComponentDecorator = (decoratedHref: string, decoratedText: string, key: Key) => {
//     return (
//       <Link className="message-bubble-hyperlink-text" key={key} rel="noopener noreferrer" target="_blank" to={decoratedHref}>
//         {decoratedText}
//       </Link>
//     );
//   };

//   const onClickRetry = () => {
//     deleteStoredMessage(currentMessage.messageId);
//     onRetrySend(text, attachment);
//   };

//   // Split the text into parts based on tagged roles
//   const taggableRolesPattern = taggableRoles.map(role => `@${role.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')}`).join('|');
//   const mentionRegex = new RegExp(`(${taggableRolesPattern})\\s*`, 'g');
//   const parts = text.split(mentionRegex);

//   return (
//     <PendingMessageWrapper
//       isPending={pending}
//       nextMessageBySameUser={nextMessageBySameUser}
//       onClickDelete={() => deleteStoredMessage(currentMessage.messageId)}
//       onClickRetry={onClickRetry}
//     >
//       {hasAvatar && <UserInfo user={user} />}
//       <div className="message-bubble-container">
//         {hasAvatar && <Avatar icon={getIconForRole(user?.jobTitle)} />}
//         <div
//           className={classnames('message-bubble-content-container', {
//             'by-me': byMe,
//             'has-attachment': !!attachment,
//             'has-avatar': hasAvatar,
//           })}
//         >
//           {attachment && (
//             <button className="message-bubble-image-button-wrapper" onClick={() => onClickAttachment(attachment)}>
//               <img crossOrigin="anonymous" src={attachment} />
//             </button>
//           )}
//           <Linkify componentDecorator={linkifyComponentDecorator} matchDecorator={matchDecorator}>
//             <Text color={byMe ? 'aviobookDarkNeutralsLight' : 'aviobookLightPrimaryAlpha'} size="SM">
//               {parts.map((part, index) => {
//                 // Check if the part is a tagged role
//                 const isTaggedRole = taggableRoles.includes(part.trim().substring(1));

//                 if (isTaggedRole) {
//                   return (
//                     <Text as="span" className="message-bubble-tagged-role" key={index} weight="bold">
//                       {part}
//                     </Text>
//                   );
//                 } else {
//                   return <span key={index}>{part}</span>;
//                 }
//               })}
//             </Text>
//           </Linkify>
//           <Text className="text-date" color={byMe ? 'aviobookWebPrimaryZulu08' : 'aviobookWebPrimaryZulu05'} size="XXS">
//             {timestamp}
//           </Text>
//         </div>
//       </div>
//     </PendingMessageWrapper>
//   );
// };