import React, { ChangeEvent, FC, forwardRef, Ref } from 'react';
import { useInputError } from '@aviobook/_hooks';
import { TIconName } from 'assets';
import classnames from 'classnames';

import Icon from '../../icon/Icon';
import InputWrapper, { TInputWrapperProps } from '../InputWrapper';

import './inputField.scss';

export type TInputFieldProps = TInputWrapperProps & {
  autoComplete?: string;
  autoFocus?: boolean;
  hasClearButton?: boolean;
  iconName?: TIconName;
  normalize?: (value: string) => string | number;
  onChange: (value: string | number, name: string) => void;
  onClearClick?: () => void;
  onClick?: () => void;
  onIconClick?: () => void;
  placeholder?: string;
  ref?: Ref<HTMLInputElement>;
  type?: 'email' | 'number' | 'password' | 'text';
  value?: string;
};

export const InputField: FC<TInputFieldProps> = forwardRef(
  (
    {
      autoComplete = 'off',
      autoFocus,
      iconName,
      normalize,
      onChange,
      onClick = () => {},
      placeholder,
      type = 'text',
      value,
      onIconClick,
      onClearClick,
      hasClearButton = false,
      ...wrapperProps
    }: TInputFieldProps,
    ref: Ref<HTMLInputElement>,
  ) => {
    const { disabled, validation, name } = wrapperProps;
    const { setDirty, showError } = useInputError(validation);

    return (
      <InputWrapper {...wrapperProps} showError={showError}>
        {iconName ? (
          <Icon className="input-icon" colorName="aviobookLightPrimaryAlpha" name={iconName} onClick={onIconClick} size={2.5} />
        ) : null}
        <input
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          className={classnames('input', { error: showError, hasClearButton, hasIcon: !!iconName })}
          disabled={disabled}
          name={name}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            const { value } = event.target;
            let normalizedValue = type === 'number' ? parseFloat(value) : value;
            if (normalize) normalizedValue = normalize(value.trim());
            onChange(normalizedValue, name);
            setDirty();
          }}
          onClick={onClick}
          placeholder={placeholder}
          ref={ref}
          type={type}
          value={value}
        />
        {hasClearButton && value ? (
          <Icon className="input-clear-icon" name="circularCrossPaddingFilled" onClick={onClearClick} size={2.5} />
        ) : null}
      </InputWrapper>
    );
  },
);
