export enum POSITION {
  LEFT = 'left',
  RIGHT = 'right',
}

export enum STATUS {
  notConnected = 'NOT_CONNECTED',
  offline = 'OFFLINE',
  online = 'ONLINE',
}

export enum USER_SYSTEM_ROLES {
  ADMIN = 'ADMIN',
  SERVICE = 'SERVICE',
  USER = 'USER',
}

export enum ROLE {
  ACM = 'ACM',
  ADMIN = 'ADMIN',
  CCM = 'CCM',
  COMM = 'COMM',
  COPILOT = 'COPILOT',
  PILOT = 'PILOT',
  DEADHEAD = 'DEADHEAD',
  DISPATCHER = 'DISPATCHER',
  GROUND_HANDLER = 'GROUND_HANDLER',
  CABIN_CREW = 'CABIN_CREW',
  GUEST = 'GUEST',
  MAINTENANCE_CREW = 'MAINTENANCE_CREW',
  SCCM = 'SCCM',
  UNKNOWN = 'UNKNOWN',
}

export const AMOUNT_OF_MESSAGES = 20;

export enum MODAL_OPTIONS {
  browse = 'Browse',
  library = 'Photo library',
  photo = 'Take photo or video',
}
