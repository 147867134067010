import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Spinner } from '@aviobook/_shared';
import { useAuthenticateCMS } from '@aviobook/cms/auth/_queries';
import Verify from '@aviobook/cms/auth/verify/Verify';

import { AuthorizedLayout } from './_routing/authorized-layout/AuthorizedLayout';
import { Login } from './auth/login/Login';
import { LoginFeedback } from './auth/loginFeedback/LoginFeedback';
import { CreateChatBot } from './connect/_screens/general/_tabs';
import { UpdateChatbot } from './connect/_screens/general/_tabs/chatbots/chatbot/UpdateChatbot';
import { Role } from './connect/_screens/roles/role/Role';
import { Connect } from './connect/Connect';

import './index-cms.scss';

export const AppCms: FC = () => {
  document.title = 'Aviobook CMS';
  const { t } = useTranslation();
  const { data: profile, isLoading } = useAuthenticateCMS();
  if (isLoading) {
    return <Spinner overlay>{t('SHARED.LOADING_APPLICATION')}</Spinner>;
  }

  return (
    <Routes>
      <Route element={profile ? <AuthenticatedRoutes /> : <NotAuthenticatedRoutes />} path="*" />
    </Routes>
  );
};

const AuthenticatedRoutes: FC = () => {
  // change title to 'Aviobook CMS' when the BE is ready to support it
  document.title = 'Aviobook CMS';

  return (
    <Routes>
      <Route element={<AuthorizedLayout />} path="connect/">
        <Route element={<Connect />} index />
        <Route element={<Navigate to="../?tab=Roles" />} path="roles" />
        <Route element={<UpdateChatbot />} path="general/chatbots/:chatbotname" />
        <Route element={<CreateChatBot />} path="general/chatbots" />
        <Route element={<Role />} path="roles/:roleid" />
      </Route>
      <Route element={<Navigate to="connect" />} path="*" />
    </Routes>
  );
};

const NotAuthenticatedRoutes: FC = () => {
  const { t } = useTranslation();

  return (
    <Routes>
      <Route element={<Login />} path="login" />
      <Route
        element={<LoginFeedback description={t('PERMISSIONDENIED_DESCRIPTION')} title={t('PERMISSIONDENIED_TITLE')} />}
        path="permission-denied"
      />
      <Route element={<Verify />} path="auth/verify/:token" />
      <Route element={<Navigate to="login" />} path="*" />
    </Routes>
  );
};
