import { Spinner } from '@aviobook/_shared';
import { SaveButton, TableLayout } from '@aviobook/cms/_shared';

import { useRolePermissionsContentTable } from './_hooks';

import './rolePermissions.scss';
export const RolePermissions = () => {
  const { table, isLoading, updatePermissions, isSaveButtonDisabled } = useRolePermissionsContentTable();

  if (isLoading) return <Spinner />;

  return (
    <main>
      <div className="role-form-top-container">
        <SaveButton isDisabled={isSaveButtonDisabled} onClick={updatePermissions} />
      </div>
      <TableLayout isSaveButtonPresent={true} saveButtonAction={updatePermissions} table={table} />
    </main>
  );
};
