import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Spinner } from '../../_shared';
import { useVerify } from '../_queries';

import './verify.scss';

const Verify: FC = () => {
  const { token } = useParams<{ token: string }>();
  const { mutate: verify } = useVerify();

  useEffect(() => {
    verify(token);
  }, []);

  return (
    <main className="verify-content">
      <Spinner theme="normal" />
    </main>
  );
};

export default Verify;
