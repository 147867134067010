export enum QUERY_KEYS {
  AUTHENTICATE = 'authenticate',
  AUTHENTICATE_CMS = 'authenticateCMS',
  CHATBOTS = 'chatbots',
  GET_ADDABLE_FLIGHTROOMS = 'getAddableFlightrooms',
  GET_ALL_FLIGHTROOMS = 'getAllFlightrooms',
  GET_FLIGHTROOMS = 'getFlightrooms',
  ORGANIZATION_SETTINGS = 'organization-settings',
  ROLE = 'role',
  ROLES = 'roles',
  QuickReplies = 'quickReplies',
}
