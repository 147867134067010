import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { InputField } from '../index';

import './searchField.scss';

type TProps = {
  placeholder?: string;
  search?: string;
  setSearch: (search: string) => void;
};

export const SearchField: FC<TProps> = ({ search, setSearch, placeholder }) => {
  const { t } = useTranslation();

  return (
    <InputField
      autoComplete="off"
      className="search-field"
      hasClearButton
      iconName="magnifyingSearchFilled"
      name="search"
      onChange={(value: string) => setSearch(value)}
      onClearClick={() => setSearch('')}
      placeholder={placeholder || t('SHARED.PLACEHOLDER.SEARCH')}
      value={search}
    />
  );
};
