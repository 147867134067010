import { HttpClient, TApiError } from '@aviobook/_http';
import { useQuery } from '@tanstack/react-query';
import { TChatbot } from 'types/src/chatbot';

function chatbot(chatbotId: string): Promise<TChatbot> {
  return HttpClient.get(`/api/v1/admin/chatbots/${chatbotId}`).then((res: TChatbot) => {
    res.keywords = res.chatbotKeywords;
    res.chatbotKeywords = [];
    return res;
  });
}

export function useChatbot(chatbotId: string) {
  return useQuery<TChatbot, TApiError>(['chatbot', chatbotId], () => chatbot(chatbotId));
}
