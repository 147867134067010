import { HttpClient, TApiError } from "@aviobook/_http";
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { TRequest } from 'types';

function getRequests(roomId: string): Promise<TRequest[]> {
  return HttpClient.get<TRequest[]>(`/api/v1/rooms/requests/${roomId}`);
}

export function useGetRequests(roomId: string, options?: UseQueryOptions<TRequest[]>) {
  return useQuery<TRequest[], TApiError>(['requests', roomId], () => getRequests(roomId), options);
}
