import { FC } from 'react';
import { Text } from '@aviobook/_shared';

import { Button} from '../index';

type TProps = {
  isDisabled?: boolean;
  label: string;
  onClick: () => void;
};

export const AddButton: FC<TProps> = ({ label, onClick, isDisabled }) => (
  <Button disabled={isDisabled} iconName="add" isOutline onClick={onClick} theme="transparent">
    <Text as="span" weight="bold">
      {label}
    </Text>
  </Button>
);
