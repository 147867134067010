import { BaseSyntheticEvent, FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { TColorName } from 'styles';

import Spinner from '../spinner/Spinner';

import './button.scss';

type TProps = {
  asText?: boolean;
  children: ReactNode;
  className?: string;
  color?: TColorName;
  disabled?: boolean;
  href?: string;
  isOutline?: boolean;
  loading?: boolean;
  onClick?: (event?: BaseSyntheticEvent) => void;
  theme?: 'neutral' | 'primary' | 'negative' | 'dark';
  type?: 'button' | 'submit' | 'reset';
};

const Button: FC<TProps> = ({
  asText,
  children,
  className = '',
  disabled,
  href,
  loading,
  onClick = () => {},
  theme = 'neutral',
  type = 'button',
  isOutline = false,
}) => {
  const cssClasses = classnames(asText ? 'as-text' : 'button', className, theme, { disabled, loading, outline: isOutline });

  function renderSpinner() {
    if (!loading) return null;
    return (
      <div className="spinner-wrapper">
        <Spinner theme={asText || isOutline ? 'normal' : 'inversed'} />
      </div>
    );
  }

  if (href) {
    return (
      <Link className={cssClasses} to={href}>
        <>
          {children}
          {renderSpinner()}
        </>
      </Link>
    );
  }
  return (
    <button className={cssClasses} disabled={disabled} onClick={onClick || (() => {})} type={type}>
      {children}
      {renderSpinner()}
    </button>
  );
};

export default Button;
