import { Text } from '../../_shared';
import { TLicense } from '../_models/license';

import '../about.scss';

export const LicenseItem = ({ name, licenses, repository }: TLicense) => {
  return (
    <li className="license-item-container">
      <Text color="aviobookDarkPrimaryBravo" size="SM" weight="semiBold">
        {name}
      </Text>
      <Text color="aviobookDarkPrimaryCharlie" size="SM">
        {licenses}
      </Text>
      <a href={repository} rel="noreferrer" target="_blank">
        <Text color="colorsAviobookDarkSecondaryDelta" size="SM" textDecoration="underline">
          {repository}
        </Text>
      </a>
    </li>
  );
};
