import { useAuthenticationContext } from '@aviobook/_context';
import { HttpClient, TApiError } from '@aviobook/_http';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { QUERY_KEYS, TProfile } from 'types';

function authenticate(): Promise<TProfile> {
  return HttpClient.get('/api/v1/auth/me');
}

export function useAuthenticate(options?: UseQueryOptions<TProfile, TApiError>) {
  const { enableAuthentication } = useAuthenticationContext();
  return useQuery<TProfile, TApiError>([QUERY_KEYS.AUTHENTICATE], authenticate, {
    enabled: enableAuthentication,
    refetchOnMount: false,
    ...options,
  });
}
