import { FC, useEffect, useState } from 'react';
import { Text } from '@aviobook/_shared';

import './quickReplies.scss';

type TProps = {
  onPressQuickReply: (quickReply: string) => void;
  quickReplies: string[];
};

export const QuickReplies: FC<TProps> = ({ onPressQuickReply, quickReplies }) => {
  const [visibleQuickReplies, setVisibleQuickReplies] = useState<string[]>([]);

  // Calculate the number of quickReplies that can fit on the screen
  const MAX_QUICK_REPLY_CHARACTERS = 100; // Adjust this value as needed
  const { QUICK_REPLY_LIMIT } = quickReplies.reduce(
    (acc, quickReply) => {
      const totalChars = acc.totalChars + quickReply.length;
      return totalChars <= MAX_QUICK_REPLY_CHARACTERS ? { QUICK_REPLY_LIMIT: acc.QUICK_REPLY_LIMIT + 1, totalChars } : acc;
    },
    { QUICK_REPLY_LIMIT: 0, totalChars: 0 },
  );

  useEffect(() => {
    if (quickReplies.length > QUICK_REPLY_LIMIT) {
      setVisibleQuickReplies([...quickReplies.slice(0, QUICK_REPLY_LIMIT), `+${quickReplies.length - QUICK_REPLY_LIMIT}`]);
    } else {
      setVisibleQuickReplies(quickReplies);
    }
  }, [quickReplies, QUICK_REPLY_LIMIT]);

  const handlePressQuickReply = (quickReply: string) => {
    if (quickReply === `+${quickReplies.length - QUICK_REPLY_LIMIT}`) {
      setVisibleQuickReplies(quickReplies);
    } else {
      onPressQuickReply(quickReply);
    }
  };

  return (
    <div className="quickReplies-container">
      {visibleQuickReplies.map((quickReply, index) => (
        <button className="quickReplies-button" key={quickReply + index} onClick={() => handlePressQuickReply(quickReply)}>
          <Text as="span" color="coreWhite100" size="SM">
            {quickReply}
          </Text>
        </button>
      ))}
    </div>
  );
};
